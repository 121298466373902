import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Ecommerce_Config } from '../Ecommerce_Config';
import { AlertDialog, ConfirmDialog } from '../dialog/dialog.component';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  more_option: string = '';
  sort_option: string = 'name-asc';
  search_qry: string = '';
  page_length: number = 110;
  pageind: number = 0;
  loading: boolean = false;
  more_action_ids: string[] = [];
  items: any = [];
  constructor(public route: ActivatedRoute, private _snackBar: MatSnackBar, private httpClient: HttpClient, public dialog: MatDialog) {
    Ecommerce_Config["page_title"] = 'Category';
  }
  getCategoryData(ev) {
    this.getCategory(ev.pageIndex);
  }
  getCategory(page) {
    this.more_option = '';
    this.more_action_ids = [];
    this.pageind = page;
    page = page + 1;
    this.loading = true;
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    var query = null;
    if (this.search_qry != '') {
      query = this.search_qry;
    }
    this.httpClient.get(Ecommerce_Config["api_url"] + 'category/' + query + '/' + this.sort_option + '/' + page, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.items = res["data"];
          this.page_length = res["count"];
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }
  toggleMoreOption(ev, code) {
    var index = (this.more_action_ids).indexOf(code);
    if (ev.checked == true && index < 0) {
      (this.more_action_ids).push(code);
    } else {
      (this.more_action_ids).splice(index, 1);
    }
    if (this.more_action_ids.length == 0) {
      this.more_option = '';
    }
  }
  checkedItem(code) {
    return (this.more_action_ids).indexOf(code) > -1;
  }
  doMoreOption() {
    var msg = '';
    switch (this.more_option) {
      case 'active':
        msg = 'Are you sure? Do you want to active these ' + this.more_action_ids.length + ' categories?';
        break;
      case 'deactive':
        msg = 'Are you sure? Do you want to deactive these ' + this.more_action_ids.length + ' categories?';
        break;
      default:
        break;
    }
    const dialogRef = this.dialog.open(ConfirmDialog, {
      minWidth: "30%",
      maxWidth: "30%",
      disableClose: true,
      data: { title: 'Confirmation', message: msg, cancel: 'No', accept: 'Yes' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result == true) {
        this.loading = true;
        var token = sessionStorage.getItem("auth_token");
        var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
        this.httpClient.put(Ecommerce_Config["api_url"] + 'categorymoreoption/' + this.more_option, { codes: this.more_action_ids }, { headers: header }).subscribe(
          (res) => {
            this.loading = false;
            this._snackBar.open(res["message"], '', {
              duration: 2000,
            });
            if (res["code"] == 200) {
              this.more_action_ids = [];
              this.more_option = '';
              this.getCategory(this.pageind);
            }
          },
          (error) => {
            this.loading = false;
            this._snackBar.open(error.error.message, '', {
              duration: 2000,
            });
          });
      }
    });
  }
  changeStatus(ev, code) {
    var msg = '';
    if (ev.checked == true) {
      msg = 'Are you sure? Do you want to enable this category?';
    } else {
      msg = 'Are you sure? Do you want to disable this category?';
    }
    const dialogRef = this.dialog.open(ConfirmDialog, {
      minWidth: "30%",
      maxWidth: "30%",
      disableClose: true,
      data: { title: 'Confirmation', message: msg, cancel: 'No', accept: 'Yes' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result == true) {
        this.loading = true;
        var token = sessionStorage.getItem("auth_token");
        var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
        this.httpClient.put(Ecommerce_Config["api_url"] + 'togglecategorystate/' + code, { isactive: ev.checked }, { headers: header }).subscribe(
          (res) => {
            this.loading = false;
            this._snackBar.open(res["message"], '', {
              duration: 2000,
            });
            if (res["code"] != 200) {
              (this.items).forEach(val => {
                if (val.code == code) {
                  val.status = !val.status;
                  return false;
                }
              });
            }
          },
          (error) => {
            this.loading = false;
            this._snackBar.open(error.error.message, '', {
              duration: 2000,
            });
          });
      } else {
        (this.items).forEach(val => {
          if (val.code == code) {
            val.status = !val.status;
            return false;
          }
        });
      }
    });
  }
  ngOnInit(): void {
    this.getCategory(0);
  }

}

@Component({
  selector: 'app-add-category',
  templateUrl: './add.category.component.html',
  styleUrls: ['./category.component.css']
})
export class AddCategoryComponent implements OnInit {
  loading = false;
  categoryForm: FormGroup;
  constructor(private _snackBar: MatSnackBar, private router: Router, private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.categoryForm = new FormGroup({
      'category_name': new FormControl('', [Validators.required]),
      'isactive': new FormControl(true, [Validators.required])
    });
  }

  addCategory() {
    if (this.categoryForm.invalid) {
      return;
    }
    this.loading = true;
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.httpClient.post(Ecommerce_Config["api_url"] + 'category', { category_name: this.categoryForm.value.category_name, isactive: this.categoryForm.value.isactive }, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.router.navigateByUrl('/category');
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }

}

@Component({
  selector: 'app-update-category',
  templateUrl: './update.category.component.html',
  styleUrls: ['./category.component.css']
})
export class UpdateCategoryComponent implements OnInit {
  category_code: string = '';
  loading: boolean = false;
  categoryForm: FormGroup;
  constructor(public route: ActivatedRoute, private _snackBar: MatSnackBar, private httpClient: HttpClient, private router: Router) {
    this.category_code = this.route.snapshot.params.code;
    this.categoryForm = new FormGroup({
      'category_name': new FormControl('', [Validators.required]),
      'isactive': new FormControl(false, [Validators.required])
    });
  }
  getCategory() {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.httpClient.get(Ecommerce_Config["api_url"] + 'category/' + this.category_code, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.categoryForm.patchValue({
            category_name: res["data"]["name"],
            isactive: res["data"]["status"]
          });
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }
  updateCategory() {
    if (this.categoryForm.invalid) {
      return;
    }
    this.loading = true;
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.httpClient.put(Ecommerce_Config["api_url"] + 'category/' + this.category_code, { category_name: this.categoryForm.value.category_name, isactive: this.categoryForm.value.isactive }, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.router.navigateByUrl('/category');
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }
  ngOnInit(): void {
    this.getCategory();
  }

}