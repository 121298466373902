import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Ecommerce_Config } from '../Ecommerce_Config';
import { ImagePreviewDialog, ConfirmDialog } from '../dialog/dialog.component';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, TableService } from '@syncfusion/ej2-angular-richtexteditor';
import { FileValidator } from 'ngx-material-file-input';

@Component({
  selector: 'app-productvariant',
  templateUrl: './productvariant.component.html',
  styleUrls: ['./productvariant.component.css']
})
export class ProductvariantComponent implements OnInit {
  product_param: string = null;
  more_option: string = '';
  sort_option: string = 'name-asc';
  search_qry: string = '';
  page_length: number = 110;
  pageind: number = 0;
  loading: boolean = false;
  more_action_ids: string[] = [];
  items: any = [];
  img_url: string = Ecommerce_Config.img_url;
  constructor(public route: ActivatedRoute, private _snackBar: MatSnackBar, private httpClient: HttpClient, public dialog: MatDialog) {
    if (this.route.snapshot.params.product) {
      this.product_param = this.route.snapshot.params.product;
    }
    Ecommerce_Config["page_title"] = 'Product Variant';
  }

  getProductvariantData(ev) {
    this.getProductvariant(ev.pageIndex);
  }
  getProductvariant(page) {
    this.more_option = '';
    this.more_action_ids = [];
    this.pageind = page;
    page = page + 1;
    this.loading = true;
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    var query = null;
    if (this.search_qry != '') {
      query = this.search_qry;
      this.product_param = null;
    }
    this.httpClient.get(Ecommerce_Config["api_url"] + 'productvariant/' + this.product_param + '/' + query + '/' + this.sort_option + '/' + page, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.items = res["data"];
          this.page_length = res["count"];
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }
  toggleMoreOption(ev, code) {
    var index = (this.more_action_ids).indexOf(code);
    if (ev.checked == true && index < 0) {
      (this.more_action_ids).push(code);
    } else {
      (this.more_action_ids).splice(index, 1);
    }
    if (this.more_action_ids.length == 0) {
      this.more_option = '';
    }
  }
  checkedItem(code) {
    return (this.more_action_ids).indexOf(code) > -1;
  }
  doMoreOption() {
    var msg = '';
    switch (this.more_option) {
      case 'active':
        msg = 'Are you sure? Do you want to active these ' + this.more_action_ids.length + ' product variants?';
        break;
      case 'deactive':
        msg = 'Are you sure? Do you want to deactive these ' + this.more_action_ids.length + ' product variants?';
        break;
      default:
        break;
    }
    const dialogRef = this.dialog.open(ConfirmDialog, {
      minWidth: "30%",
      maxWidth: "30%",
      disableClose: true,
      data: { title: 'Confirmation', message: msg, cancel: 'No', accept: 'Yes' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result == true) {
        this.loading = true;
        var token = sessionStorage.getItem("auth_token");
        var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
        this.httpClient.put(Ecommerce_Config["api_url"] + 'productvariantmoreoption/' + this.more_option, { codes: this.more_action_ids }, { headers: header }).subscribe(
          (res) => {
            this.loading = false;
            this._snackBar.open(res["message"], '', {
              duration: 2000,
            });
            if (res["code"] == 200) {
              this.more_action_ids = [];
              this.more_option = '';
              this.getProductvariant(this.pageind);
            }
          },
          (error) => {
            this.loading = false;
            this._snackBar.open(error.error.message, '', {
              duration: 2000,
            });
          });
      }
    });
  }
  changeStatus(ev, code) {
    var msg = '';
    if (ev.checked == true) {
      msg = 'Are you sure? Do you want to enable this product variant?';
    } else {
      msg = 'Are you sure? Do you want to disable this product variant?';
    }
    const dialogRef = this.dialog.open(ConfirmDialog, {
      minWidth: "30%",
      maxWidth: "30%",
      disableClose: true,
      data: { title: 'Confirmation', message: msg, cancel: 'No', accept: 'Yes' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result == true) {
        this.loading = true;
        var token = sessionStorage.getItem("auth_token");
        var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
        this.httpClient.put(Ecommerce_Config["api_url"] + 'toggleproductvariantstate/' + code, { isactive: ev.checked }, { headers: header }).subscribe(
          (res) => {
            this.loading = false;
            this._snackBar.open(res["message"], '', {
              duration: 2000,
            });
            if (res["code"] != 200) {
              (this.items).forEach(val => {
                if (val.code == code) {
                  val.status = !val.status;
                  return false;
                }
              });
            }
          },
          (error) => {
            this.loading = false;
            this._snackBar.open(error.error.message, '', {
              duration: 2000,
            });
          });
      } else {
        (this.items).forEach(val => {
          if (val.code == code) {
            val.status = !val.status;
            return false;
          }
        });
      }
    });
  }
  previewImage(ttl, path) {
    const dialogRef = this.dialog.open(ImagePreviewDialog, {
      minWidth: "30%",
      maxWidth: "30%",
      disableClose: true,
      data: { title: ttl, path: path, accept: 'Close' }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }
  ngOnInit(): void {
    this.getProductvariant(0);
  }

}

@Component({
  selector: 'app-add-productvariant',
  templateUrl: './add.productvariant.component.html',
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, TableService],
  styleUrls: ['./productvariant.component.css']
})
export class AddProductvariantComponent implements OnInit {
  public tools: object = {
    items: [
      'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
      'FontSize', 'FontColor', 'BackgroundColor', '|',
      'Undo', 'Redo', '|',
      'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
      'CreateLink', 'CreateTable']
  };
  loading = false;
  productvariantForm: FormGroup;
  productCtrl: FormControl = new FormControl();
  unitCtrl: FormControl = new FormControl();
  products: any = [];
  units: any = [];
  img_paths: string[] = [];
  loading_options: boolean = false;
  perunit: string = '';
  perunitslash: string = '';
  constructor(private _snackBar: MatSnackBar, private router: Router, private httpClient: HttpClient) { }
  ngOnInit(): void {
    this.productvariantForm = new FormGroup({
      'variant_name': new FormControl('', [Validators.required]),
      'product': new FormControl('', [Validators.required]),
      'variant_description': new FormControl(''),
      'variant_unit': new FormControl('', Validators.required),
      'gallery': new FormControl(undefined, [FileValidator.maxContentSize(Ecommerce_Config.max_file_size)]),
      'retail_price': new FormControl(undefined, [Validators.required]),
      'wholesale_price': new FormControl(undefined, [Validators.required]),
      'has_discount': new FormControl(false),
      'discount_price': new FormControl(0, [Validators.required]),
      'min_qty': new FormControl(1, [Validators.required]),
      'step_qty': new FormControl(1, [Validators.required]),
      'isactive': new FormControl(true)
    });
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.productCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.products = [];
          this.loading_options = true;
        }),
        switchMap(srch => this.httpClient.get<any>(Ecommerce_Config["api_url"] + 'product_autocomplete/' + srch, { headers: header })
          .pipe(
            finalize(() => {
              this.loading_options = false
            }),
          )
        )
      )
      .subscribe(res => {
        if (res["code"] == 200) {
          this.products = res["data"];
        } else {
          this.products = [];
        }
      });
    this.unitCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.units = [];
          this.loading_options = true;
        }),
        switchMap(srch => this.httpClient.get<any>(Ecommerce_Config["api_url"] + 'unit_autocomplete/' + srch, { headers: header })
          .pipe(
            finalize(() => {
              this.loading_options = false
            }),
          )
        )
      )
      .subscribe(res => {
        if (res["code"] == 200) {
          this.units = res["data"];
        } else {
          this.units = [];
        }
      });
  }
  selectedOption(val, param, array, idparam, matchval) {
    this[array].forEach(v => {
      if (v[matchval] == val) {
        this.productvariantForm.patchValue({
          [param]: v[idparam]
        });
        if (param == 'variant_unit') {
          this.perunit = v.sign;
          this.perunitslash = '/' + v.sign;
        }
        return false;
      }
    });
  }
  addProductvariant() {
    if (this.productvariantForm.invalid) {
      return;
    }
    this.loading = true;
    var formData = new FormData();
    var active = '1';
    var has_discount = '1';
    if (this.productvariantForm.value.isactive == false) {
      active = '0';
    }
    if (this.productvariantForm.value.has_discount == false) {
      has_discount = '0';
    }
    formData.append('variant_name', this.productvariantForm.value.variant_name)
    formData.append('isactive', active);
    formData.append('variant_description', this.productvariantForm.value.variant_description);
    formData.append('product', this.productvariantForm.value.product);
    formData.append('variant_unit', this.productvariantForm.value.variant_unit);
    formData.append('retail_price', this.productvariantForm.value.retail_price);
    formData.append('has_discount', has_discount);
    formData.append('discount_price', this.productvariantForm.value.discount_price);
    formData.append('wholesale_price', this.productvariantForm.value.wholesale_price);
    formData.append('min_qty', this.productvariantForm.value.min_qty);
    formData.append('step_qty', this.productvariantForm.value.step_qty);
    if (this.productvariantForm.value.gallery != null && typeof this.productvariantForm.value.gallery['_files'] != 'undefined') {
      console.log(this.productvariantForm.value.gallery['_files']);
      for (var i = 0; i < this.productvariantForm.value.gallery['_files'].length; i++) {
        formData.append('gallery' + i, this.productvariantForm.value.gallery['_files'][i]);
      }
    }
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.httpClient.post(Ecommerce_Config["api_url"] + 'productvariant', formData, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.router.navigateByUrl('/productvariant');
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }
  removeImage(index) {
    (this.productvariantForm.value.gallery['_files']).splice(index, 1);
    (this.img_paths).splice(index, 1);
    if (this.img_paths.length == 0) {
      this.productvariantForm.patchValue({
        gallery: null
      });
    }
  }
  fileProgress(event, param) {
    this[param] = [];
    if (event.target.files.length > 0) {
      for (var i = 0; i < event.target.files.length; i++) {
        if (event.target.files[i]) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            (this[param]).push(e.target.result);
          };
          reader.readAsDataURL(event.target.files[i]);
        }
      }
    }
  }
}

@Component({
  selector: 'app-update-productvariant',
  templateUrl: './update.productvariant.component.html',
  styleUrls: ['./productvariant.component.css']
})
export class UpdateProductvariantComponent implements OnInit {
  public tools: object = {
    items: [
      'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
      'FontSize', 'FontColor', 'BackgroundColor', '|',
      'Undo', 'Redo', '|',
      'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
      'CreateLink', 'CreateTable']
  };
  productvariant_code: string = '';
  loading: boolean = false;
  loading_options: boolean = false;
  productvariantForm: FormGroup;
  productCtrl: FormControl = new FormControl();
  unitCtrl: FormControl = new FormControl();
  products: any = [];
  units: any = [];
  perunit: string = '';
  constructor(public route: ActivatedRoute, private _snackBar: MatSnackBar, private httpClient: HttpClient, private router: Router) {
    this.productvariant_code = this.route.snapshot.params.code;
    this.productvariantForm = new FormGroup({
      'variant_name': new FormControl('', [Validators.required]),
      'product': new FormControl('', [Validators.required]),
      'variant_description': new FormControl(''),
      'variant_unit': new FormControl('', Validators.required),
      'min_qty': new FormControl(1, [Validators.required]),
      'step_qty': new FormControl(1, [Validators.required]),
      'isactive': new FormControl(true)
    });
  }
  getProductvariant() {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.httpClient.get(Ecommerce_Config["api_url"] + 'productvariant/' + this.productvariant_code, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.productvariantForm.patchValue({
            variant_name: res["data"]["name"],
            product: res["data"]["mv_product_id"],
            variant_description: res["data"]["description"],
            variant_unit: res["data"]["mv_unit_id"],
            min_qty: res["data"]["start_value"],
            step_qty: res["data"]["step_value"],
            isactive: res["data"]["status"]
          });
          this.perunit = res["data"]["sign"];
          this.productCtrl.setValue(res["data"]["product_name"]);
          this.unitCtrl.setValue(res["data"]["unit"]);
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }
  updateProductvariant() {
    if (this.productvariantForm.invalid) {
      return;
    }
    this.loading = true;
    var formData = new FormData();
    var active = '1';
    if (this.productvariantForm.value.isactive == false) {
      active = '0';
    }
    formData.append('variant_name', this.productvariantForm.value.variant_name)
    formData.append('isactive', active);
    formData.append('variant_description', this.productvariantForm.value.variant_description);
    formData.append('product', this.productvariantForm.value.product);
    formData.append('variant_unit', this.productvariantForm.value.variant_unit);
    formData.append('min_qty', this.productvariantForm.value.min_qty);
    formData.append('step_qty', this.productvariantForm.value.step_qty);
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.httpClient.put(Ecommerce_Config["api_url"] + 'productvariant/' + this.productvariant_code, formData, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.router.navigateByUrl('/productvariant');
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }
  selectedOption(val, param, array, idparam, matchval) {
    this[array].forEach(v => {
      if (v[matchval] == val) {
        this.productvariantForm.patchValue({
          [param]: v[idparam]
        });
        if (param == 'variant_unit') {
          this.perunit = v.sign;
        }
        return false;
      }
    });
  }
  ngOnInit(): void {
    this.getProductvariant();
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.productCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.products = [];
          this.loading_options = true;
        }),
        switchMap(srch => this.httpClient.get<any>(Ecommerce_Config["api_url"] + 'product_autocomplete/' + srch, { headers: header })
          .pipe(
            finalize(() => {
              this.loading_options = false
            }),
          )
        )
      )
      .subscribe(res => {
        if (res["code"] == 200) {
          this.products = res["data"];
        } else {
          this.products = [];
        }
      });
    this.unitCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.units = [];
          this.loading_options = true;
        }),
        switchMap(srch => this.httpClient.get<any>(Ecommerce_Config["api_url"] + 'unit_autocomplete/' + srch, { headers: header })
          .pipe(
            finalize(() => {
              this.loading_options = false
            }),
          )
        )
      )
      .subscribe(res => {
        if (res["code"] == 200) {
          this.units = res["data"];
        } else {
          this.units = [];
        }
      });
  }
}

@Component({
  selector: 'app-gallery-productvariant',
  templateUrl: './gallery.productvariant.component.html',
  styleUrls: ['./productvariant.component.css']
})
export class GalleryProductvariantComponent implements OnInit {
  loading = false;
  productvariantGalleryForm: FormGroup;
  img_paths: string[] = [];
  items: any = [];
  productvariant_code: string = '';
  image_url: string = Ecommerce_Config['img_url'];
  perunitslash: string = '';
  constructor(public route: ActivatedRoute, private _snackBar: MatSnackBar, private router: Router, private httpClient: HttpClient, public dialog: MatDialog) {
    this.productvariant_code = this.route.snapshot.params.code;
  }
  getProductvariantGallery() {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.httpClient.get(Ecommerce_Config["api_url"] + 'productvariantgallery/' + this.productvariant_code, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.items = res["data"];
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }
  ngOnInit(): void {
    this.getProductvariantGallery();
    this.productvariantGalleryForm = new FormGroup({
      'gallery': new FormControl(undefined, [Validators.required, FileValidator.maxContentSize(Ecommerce_Config.max_file_size)])
    });
  }
  addProductvariantGallery() {
    if (this.productvariantGalleryForm.invalid) {
      return;
    }
    this.loading = true;
    var formData = new FormData();
    if (this.productvariantGalleryForm.value.gallery != null && typeof this.productvariantGalleryForm.value.gallery['_files'] != 'undefined') {
      console.log(this.productvariantGalleryForm.value.gallery['_files']);
      for (var i = 0; i < this.productvariantGalleryForm.value.gallery['_files'].length; i++) {
        formData.append('gallery' + i, this.productvariantGalleryForm.value.gallery['_files'][i]);
      }
    }
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.httpClient.post(Ecommerce_Config["api_url"] + 'productvariantgallery/' + this.productvariant_code, formData, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.getProductvariantGallery();
          this.productvariantGalleryForm.patchValue({
            gallery: null
          });
          this.img_paths = [];
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }
  removeImage(index) {
    (this.productvariantGalleryForm.value.gallery['_files']).splice(index, 1);
    (this.img_paths).splice(index, 1);
    if (this.img_paths.length == 0) {
      this.productvariantGalleryForm.patchValue({
        gallery: null
      });
    }
  }
  deleteImage(id) {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      minWidth: "30%",
      maxWidth: "30%",
      disableClose: true,
      data: { title: 'Confirmation', message: 'Are you sure? Do you want to permanently delete this image?', cancel: 'No', accept: 'Yes' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result == true) {
        this.loading = true;
        var token = sessionStorage.getItem("auth_token");
        var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
        this.httpClient.delete(Ecommerce_Config["api_url"] + 'productvariantgallery/' + id, { headers: header }).subscribe(
          (res) => {
            this.loading = false;
            if (res["code"] == 200) {
              this.getProductvariantGallery();
            } else {
              this._snackBar.open(res["message"], '', {
                duration: 2000,
              });
            }
          },
          (error) => {
            this.loading = false;
            this._snackBar.open(error.error.message, '', {
              duration: 2000,
            });
          });
      }
    });
  }
  fileProgress(event, param) {
    this[param] = [];
    if (event.target.files.length > 0) {
      for (var i = 0; i < event.target.files.length; i++) {
        if (event.target.files[i]) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            (this[param]).push(e.target.result);
          };
          reader.readAsDataURL(event.target.files[i]);
        }
      }
    }
  }
}

@Component({
  selector: 'app-price-productvariant',
  templateUrl: './price.productvariant.component.html',
  styleUrls: ['./productvariant.component.css']
})
export class PriceProductvariantComponent implements OnInit {
  productvariant_code: string = '';
  loading: boolean = false;
  productvariantPriceForm: FormGroup;
  perunit: string = '';
  variantname: string = '';
  constructor(public route: ActivatedRoute, private _snackBar: MatSnackBar, private httpClient: HttpClient, private router: Router) {
    this.productvariant_code = this.route.snapshot.params.code;
    this.productvariantPriceForm = new FormGroup({
      'retail_price': new FormControl(undefined, [Validators.required]),
      'wholesale_price': new FormControl(undefined, [Validators.required]),
      'has_discount': new FormControl(false),
      'discount_price': new FormControl(0, [Validators.required])
    });
  }
  getProductvariantPrice() {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.httpClient.get(Ecommerce_Config["api_url"] + 'productvariantprice/' + this.productvariant_code, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.productvariantPriceForm.patchValue({
            retail_price: res["data"]["price"],
            wholesale_price: res["data"]["price_wholesale"],
            has_discount: res["data"]["is_discount"],
            discount_price: res["data"]["discount_price"]
          });
          this.variantname = res["data"]["name"];
          this.perunit = '/' + res["data"]["sign"];
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }
  updateProductvariantPrice() {
    if (this.productvariantPriceForm.invalid) {
      return;
    }
    this.loading = true;
    var formData = new FormData();
    var has_discount = '1';
    if (this.productvariantPriceForm.value.has_discount == false) {
      has_discount = '0';
    }
    formData.append('retail_price', this.productvariantPriceForm.value.retail_price);
    formData.append('has_discount', has_discount);
    formData.append('discount_price', this.productvariantPriceForm.value.discount_price);
    formData.append('wholesale_price', this.productvariantPriceForm.value.wholesale_price);
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.httpClient.put(Ecommerce_Config["api_url"] + 'productvariantprice/' + this.productvariant_code, formData, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.router.navigateByUrl('/productvariant');
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }
  ngOnInit(): void {
    this.getProductvariantPrice();
  }
}