<app-toolbar></app-toolbar>
<div *ngIf="route.children.length === 0">
    <mat-progress-bar *ngIf="loading == true" mode="indeterminate" color="warn"></mat-progress-bar>
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" class="toolbar">
        <div class="action-lbl">
            Actions:
        </div>
        <button mat-raised-button color="accent" routerLink="/productvariant/add">Add Product Variant</button>
        <button mat-raised-button (click)="getProductvariant(pageind)">Refresh</button>
        <mat-form-field [floatLabel]="'never'">
            <mat-label>More Options</mat-label>
            <mat-select [(ngModel)]="more_option" [disabled]="more_action_ids.length < 2"
                [disableOptionCentering]="true" (selectionChange)="doMoreOption()">
                <mat-option value="active">
                    Active
                </mat-option>
                <mat-option value="deactive">
                    Deactive
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field [floatLabel]="'never'">
            <mat-label>Sort by</mat-label>
            <mat-select [(ngModel)]="sort_option" (selectionChange)="getProductvariant(pageind)"
                [disableOptionCentering]="true">
                <mat-option value="name-asc">
                    Name A-Z
                </mat-option>
                <mat-option value="name-desc">
                    Name Z-A
                </mat-option>
                <mat-option value="price-asc">
                    Price Low-High
                </mat-option>
                <mat-option value="price-desc">
                    Price High-Low
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field [floatLabel]="'never'">
            <input type="text" matInput placeholder="Search" [(ngModel)]="search_qry" (input)="getProductvariant(0)" />
        </mat-form-field>
        <mat-paginator [length]="page_length" [pageSize]="20" [pageIndex]="pageind"
            (page)="getProductvariantData($event)" [hidePageSize]="true">
        </mat-paginator>
    </mat-toolbar-row>
    <div class="container">
        <mat-list>
            <mat-list-item *ngFor="let item of items; let lastItem = last">
                <mat-checkbox mat-list-icon [checked]="checkedItem(item.code)"
                    (change)="toggleMoreOption($event, item.code)"></mat-checkbox>
                <div mat-line> {{item.name}} in {{item.product_name}}</div>
                <div mat-line> ₹{{item.price | number: '1.2-2'}}/{{item.sign}} - {{item.stock_in_hand|number}}
                    {{item.sign}} In Stock </div>
                <mat-action-list>
                    <button mat-button routerLink="/productvariant/gallery/{{item.code}}">
                        Gallery
                    </button>
                </mat-action-list>
                <mat-action-list>
                    <button mat-button routerLink="/productvariant/update/{{item.code}}">
                        Edit
                    </button>
                </mat-action-list>
                <mat-action-list>
                    <button mat-button routerLink="/productvariant/price/{{item.code}}">
                        Change Price
                    </button>
                </mat-action-list>
                <mat-action-list>
                    <mat-slide-toggle [(ngModel)]="item.status" (change)="changeStatus($event, item.code)">
                    </mat-slide-toggle>
                </mat-action-list>
                <mat-divider *ngIf="!lastItem"></mat-divider>
            </mat-list-item>
        </mat-list>
    </div>
</div>
<router-outlet></router-outlet>