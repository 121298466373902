<div class="header-logo">
    <img src="assets/aahhaa.jpg" alt="image" />
</div>
<mat-nav-list>
    <a mat-list-item routerLink="/dashboard" routerLinkActive="active-link">
        &nbsp;&nbsp;&nbsp;Dashboard
    </a>
    <a mat-list-item routerLink="/category" routerLinkActive="active-link">
        &nbsp;&nbsp;&nbsp;Category
    </a>
    <a mat-list-item routerLink="/product" routerLinkActive="active-link">
        &nbsp;&nbsp;&nbsp;Product
    </a>
    <a mat-list-item routerLink="/productvariant" routerLinkActive="active-link">
        &nbsp;&nbsp;&nbsp;Product Variant
    </a>
    <a mat-list-item routerLink="/purchase/add" routerLinkActive="active-link">
        &nbsp;&nbsp;&nbsp;Purchase
    </a>
    <a mat-list-item routerLink="/sale/add" routerLinkActive="active-link">
        &nbsp;&nbsp;&nbsp;Sale
    </a>
    <a mat-list-item routerLink="/report" routerLinkActive="active-link">
        &nbsp;&nbsp;&nbsp;Report
    </a>
    <a mat-list-item routerLink="/denomination" routerLinkActive="active-link">
        &nbsp;&nbsp;&nbsp;Denomination
    </a>
</mat-nav-list>