<div class="container full-height white-bg">
    <div fxLayoutAlign="center center" fxLayoutGap="20px" fxLayout="column">
        <img src="assets/logo.png" alt="microview" class="microview" />
        <div class="mat-title">WE PROVIDING A GOOD SERVICE</div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" fxLayout.xs="column">
        <form [formGroup]="loginForm" autocomplete="off" (ngSubmit)="loginAdmin()" fxLayout="column"
            fxLayoutAlign="center stretch" fxLayoutGap="10px" style="width: 30%;">
            <mat-form-field appearance="legacy">
                <mat-label>User name</mat-label>
                <input matInput type="text" placeholder="" formControlName="user_name" #email />
                <mat-error *ngIf="loginForm.get('user_name').hasError('required')">
                    User name is required
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="legacy">
                <mat-label>Password</mat-label>
                <input matInput type="password" placeholder="" formControlName="password" />
                <mat-error *ngIf="loginForm.get('password').hasError('required')">
                    Password is required
                </mat-error>
            </mat-form-field>
            <button [disabled]="!loginForm.valid || loading" mat-raised-button color="primary" type="submit">
                <span *ngIf="loading == false">Login</span>
                <mat-progress-spinner *ngIf="loading == true" color="accent" mode="indeterminate" strokeWidth="4"
                    diameter="40" class="auto-margin"></mat-progress-spinner>
            </button>
        </form>
    </div>
    <br/>
    <br/>
    <p style="text-align: center;">A product of <a href="https://www.mdmacrm.com/" target="_blank"> <img src="./assets/microview.png" alt="Champa" style="width: 120px;" /></a></p>
    <p style="text-align: center;">Enquiry? <a href="tel:+91 87787 26838"> Call at +91 87787 26838</a></p>
</div>