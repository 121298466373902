<mat-toolbar color="primary">
    <mat-toolbar-row class="toolbar-primary" fxLayout="row" fxLayoutAlign="end end" style="height: auto;">
        <div fxLayout="column" fxLayoutAlign="end center">
            <div class="page-title">{{title}}</div>
            <div style="position: absolute; left: 25%; top: 25%;">
                <img [src]="image_url + logo" alt="image" style="height: 90px;" />
                <div style="display: inline-block; text-align: center; margin-left: 15px;" class="black-font mat-title">
                    <span style="font-size: 28px;">{{storename}}</span><br />{{address1}}, {{address2}}<br />Phone No. -
                    {{phone}}
                </div>
            </div>
            <img src="assets/icon.png" style="height: 80px; padding: 10px 0px;" alt="image" />
        </div>
    </mat-toolbar-row>
    <mat-toolbar-row class="toolbar-secondary" style="position: relative;">
        <div style="position: absolute; top: -150%;">
            <div style="font-size: 14px; color: #000000;">Product of</div>
            <a href="https://www.mdmacrm.com/" style="display: block;" target="_blank"><img src="assets/microview.png"
                    alt="image" style="width: 180px; vertical-align: middle;" /></a>
        </div>
        <span class="spacer"></span>
        <mat-nav-list fxLayout="row" fxLayoutAlign="center center">
            <!--<a mat-list-item routerLink="/home" routerLinkActive="active-link">
                Home
            </a>-->
            <a mat-list-item routerLink="/dashboard" routerLinkActive="active-link">
                Dashboard
            </a>
            <a mat-list-item [matMenuTriggerFor]="master"
                [ngClass]="(title == 'Category' || title == 'Product' || title == 'Product Variant' || title == 'User') ? 'active-link': ''">Masters</a>
            <mat-menu #master="matMenu">
                <a mat-menu-item routerLink="/category" routerLinkActive="active-link">
                    Category
                </a>
                <a mat-menu-item routerLink="/product" routerLinkActive="active-link">
                    Product
                </a>
                <a mat-menu-item routerLink="/productvariant" routerLinkActive="active-link">
                    Product Variant
                </a>
                <a mat-menu-item routerLink="/user" routerLinkActive="active-link">
                    User
                </a>
            </mat-menu>
            <a mat-list-item routerLink="/purchase" routerLinkActive="active-link">
                Purchase
            </a>
            <a mat-list-item routerLink="/sale/add" routerLinkActive="active-link">
                Sale
            </a>
            <a mat-list-item routerLink="/payment" routerLinkActive="active-link">
                Payment
            </a>
            <a mat-list-item routerLink="/expense" routerLinkActive="active-link">
                Expense
            </a>
            <a mat-list-item [matMenuTriggerFor]="report"
                [ngClass]="(title == 'Sale & Stock Report' || title == 'Payment Report') ? 'active-link': ''">Reports</a>
            <mat-menu #report="matMenu">
                <a mat-menu-item routerLink="/report" routerLinkActive="active-link">
                    Sale &amp; Stock
                </a>
                <a mat-menu-item routerLink="/ledger" routerLinkActive="active-link">
                    Payment
                </a>
            </mat-menu>
        </mat-nav-list>
        <button mat-button aria-label="Example icon-button with a heart icon" [matMenuTriggerFor]="menu"
            style="background-color: rgb(0, 171, 232);">
            {{username}}
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item routerLink="/changepass">Change Password</button>
            <button mat-menu-item (click)="logout()">Logout</button>
        </mat-menu>
    </mat-toolbar-row>
</mat-toolbar>