<div class="container notoolbar">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" fxLayout.xs="column">
        <button mat-raised-button routerLink="/payment">Back to payment</button>
    </div>
    <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="20px" fxLayout.xs="column">
        <mat-form-field>
            <mat-label>User</mat-label>
            <input #userdropdown matInput placeholder="" aria-label="User" [matAutocomplete]="auto"
                [formControl]="userCtrl" />
            <mat-autocomplete #auto="matAutocomplete"
                (optionSelected)="selectedOption($event.option.value, 'user', 'users', 'mv_user_id', 'name')">
                <mat-option *ngIf="loading_options">
                    <mat-progress-spinner [mode]="'indeterminate'" [strokeWidth]="3" [diameter]="25">
                    </mat-progress-spinner>
                </mat-option>
                <mat-option *ngFor="let row of users" [value]="row.name">
                    {{row.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="legacy" class="mat-subheading-2">
            <mat-label>Amt. Paid(F1) ₹</mat-label>
            <input matInput #paid_amt_ele type="number" step="1" placeholder="" [(ngModel)]="paid_amt" />
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="addPayment()">
            <span *ngIf="loading == false">Make Payment</span>
            <mat-progress-spinner *ngIf="loading == true" color="accent" mode="indeterminate" strokeWidth="4"
                diameter="40" class="auto-margin"></mat-progress-spinner>
        </button>
    </div>
    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px" fxLayout.xs="column">
        <h2>Pending payment bills</h2>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="selected">
                <th mat-header-cell *matHeaderCellDef> <mat-checkbox disabled>&nbsp;</mat-checkbox> </th>
                <td mat-cell *matCellDef="let element"> <mat-checkbox
                        (change)="addInvoiceToPay($event, element.mv_inventory_id, element.balance_to_be_pay)"
                        [(ngModel)]="element.selected">&nbsp;</mat-checkbox> </td>
            </ng-container>
            <ng-container matColumnDef="mv_inventory_id">
                <th mat-header-cell *matHeaderCellDef> Bill No. </th>
                <td mat-cell *matCellDef="let element"> #{{element.order_no}} </td>
            </ng-container>

            <ng-container matColumnDef="grand_total">
                <th mat-header-cell *matHeaderCellDef> Bill Total </th>
                <td mat-cell *matCellDef="let element"> ₹{{element.grand_total| number:'1.2-2'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="balance_to_be_pay">
                <th mat-header-cell *matHeaderCellDef> Balance to be pay </th>
                <td mat-cell *matCellDef="let element"> ₹{{element.balance_to_be_pay| number:'1.2-2'}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>