<app-toolbar></app-toolbar>
<mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChanged($event);">
    <mat-tab label="Payment summary">
        <mat-progress-bar *ngIf="loading == true" mode="indeterminate" color="warn"></mat-progress-bar>
        <div class="container denomination notoolbar">
            <div fxLayout="row" fxLayoutAlign="end stretch" fxLayoutGap="30px" class="padding-bottom-20">
                <button mat-raised-button color="primary" (click)="getPayments()" [disabled]="loading">Refresh</button>
                <button mat-raised-button color="warn" (click)="getPaymentsPDF()" [disabled]="loadingPDF">
                    <span *ngIf="loadingPDF == false">Download as PDF</span>
                    <mat-progress-spinner *ngIf="loadingPDF == true" color="accent" mode="indeterminate" strokeWidth="2"
                        diameter="20" class="auto-margin"></mat-progress-spinner>
                </button>
                <button mat-raised-button color="accent" (click)="getPaymentsExcel()" [disabled]="loadingExcel">
                    <span *ngIf="loadingExcel == false">Download as Excel</span>
                    <mat-progress-spinner *ngIf="loadingExcel == true" color="accent" mode="indeterminate"
                        strokeWidth="2" diameter="20" class="auto-margin"></mat-progress-spinner>
                </button>
            </div>
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">
                <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef> User </th>
                    <td mat-cell *matCellDef="let element"> {{element.user }}</td>
                    <td mat-footer-cell *matFooterCellDef>&nbsp;</td>
                </ng-container>

                <ng-container matColumnDef="paid_amt">
                    <th mat-header-cell *matHeaderCellDef> Total Paid </th>
                    <td mat-cell *matCellDef="let element"> ₹{{element.total_paid|number:'1.2-2'}}</td>
                    <td mat-footer-cell *matFooterCellDef>₹{{getTotal('dataSource', 'total_paid')|number:'1.2-2'}}</td>
                </ng-container>

                <ng-container matColumnDef="outstanding_amt">
                    <th mat-header-cell *matHeaderCellDef> Total Outstanding </th>
                    <td mat-cell *matCellDef="let element"> ₹{{element.total_balance|number:'1.2-2'}}</td>
                    <td mat-footer-cell *matFooterCellDef>₹{{getTotal('dataSource', 'total_balance')|number:'1.2-2'}}</td>
                </ng-container>

                <ng-container matColumnDef="last_paid_amt">
                    <th mat-header-cell *matHeaderCellDef> Last Paid Amt. </th>
                    <td mat-cell *matCellDef="let element"> ₹{{element.last_paid_amt|number:'1.2-2'}}</td>
                    <td mat-footer-cell *matFooterCellDef>&nbsp;</td>
                </ng-container>

                <ng-container matColumnDef="last_paid_on">
                    <th mat-header-cell *matHeaderCellDef> Last Paid On </th>
                    <td mat-cell *matCellDef="let element"> {{element.last_paid_date| date}}</td>
                    <td mat-footer-cell *matFooterCellDef>&nbsp;</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
            </table>
        </div>
    </mat-tab>
    <mat-tab label="Payment detail">
        <div class="container denomination notoolbar">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                <mat-form-field appearance="fill">
                    <mat-label>User</mat-label>
                    <input #vendordetail matInput placeholder="" aria-label="User" [matAutocomplete]="auto"
                        [formControl]="userCtrl" />
                    <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="selectedOption($event.option.value, 'user', 'users', 'mv_user_id', 'name')">
                        <mat-option *ngIf="loading_options">
                            <mat-progress-spinner [mode]="'indeterminate'" [strokeWidth]="3" [diameter]="25">
                            </mat-progress-spinner>
                        </mat-option>
                        <mat-option *ngFor="let row of users" [value]="row.name">
                            {{row.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Choose a from date</mat-label>
                    <input matInput [matDatepicker]="picker" [(ngModel)]="from" />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Choose a to date</mat-label>
                    <input matInput [matDatepicker]="picker2" [(ngModel)]="to" />
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
                <button mat-raised-button color="primary" (click)="getPaymentDetail()" [disabled]="loading">
                    <span *ngIf="loading == false" class="mat-display-1">Get Result</span>
                    <mat-progress-spinner *ngIf="loading == true" color="accent" mode="indeterminate" strokeWidth="4"
                        diameter="40" class="auto-margin"></mat-progress-spinner>
                </button>
                <button mat-raised-button color="warn" (click)="getPaymentDetailPDF()" [disabled]="loadingPDF">
                    <span *ngIf="loadingPDF == false">Download as PDF</span>
                    <mat-progress-spinner *ngIf="loadingPDF == true" color="accent" mode="indeterminate" strokeWidth="2"
                        diameter="20" class="auto-margin"></mat-progress-spinner>
                </button>
                <button mat-raised-button color="accent" (click)="getPaymentDetailExcel()" [disabled]="loadingExcel">
                    <span *ngIf="loadingExcel == false">Download as Excel</span>
                    <mat-progress-spinner *ngIf="loadingExcel == true" color="accent" mode="indeterminate"
                        strokeWidth="2" diameter="20" class="auto-margin"></mat-progress-spinner>
                </button>
            </div>
            <table mat-table [dataSource]="dataSourceDetail" class="mat-elevation-z8" style="width: 100%;">
                <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef> User </th>
                    <td mat-cell *matCellDef="let element"> {{element.user}}</td>
                    <td mat-footer-cell *matFooterCellDef>&nbsp;</td>
                </ng-container>

                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef> Date </th>
                    <td mat-cell *matCellDef="let element"> {{element.date | date}}</td>
                    <td mat-footer-cell *matFooterCellDef>&nbsp;</td>
                </ng-container>

                <ng-container matColumnDef="order">
                    <th mat-header-cell *matHeaderCellDef> Behalf of Bill# </th>
                    <td mat-cell *matCellDef="let element"> #{{element.order_id}}</td>
                    <td mat-footer-cell *matFooterCellDef>&nbsp;</td>
                </ng-container>

                <ng-container matColumnDef="paid_amount">
                    <th mat-header-cell *matHeaderCellDef> Paid Amount </th>
                    <td mat-cell *matCellDef="let element"> ₹{{element.paid_amount|number:'1.2-2'}}</td>
                    <td mat-footer-cell *matFooterCellDef>₹{{getTotal('dataSourceDetail', 'paid_amount')|number:'1.2-2'}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsDetail"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsDetail;"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumnsDetail"></tr>
            </table>
        </div>
    </mat-tab>
</mat-tab-group>