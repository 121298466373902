import { Component, OnInit } from '@angular/core';
import { Ecommerce_Config } from '../Ecommerce_Config';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertDialog } from '../dialog/dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-changepass',
  templateUrl: './changepass.component.html',
  styleUrls: ['./changepass.component.css']
})
export class ChangepassComponent implements OnInit {
  loading: boolean = false;
  changePasswordForm: FormGroup;
  constructor(private httpClient: HttpClient, private _snackBar: MatSnackBar, public dialog: MatDialog) {
    Ecommerce_Config.page_title = 'Change Password';
  }

  ngOnInit(): void {
    this.changePasswordForm = new FormGroup({
      'old_password': new FormControl(null, [Validators.required]),
      'new_password': new FormControl(null, [Validators.required]),
      'confirm_new_password': new FormControl(null, [Validators.required])
    }, this.pwdMatchValidator);
  }
  pwdMatchValidator(frm: FormGroup) {
    return frm.get('new_password').value === frm.get('confirm_new_password').value
      ? null : { 'mismatch': true };
  }
  changePassword() {
    if (!this.changePasswordForm.valid) {
      return false;
    }
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    var formData = { password: this.changePasswordForm.value.old_password, new_password: this.changePasswordForm.value.new_password };
    this.loading = true;
    this.httpClient.post(Ecommerce_Config["api_url"] + 'change_admin_password', formData, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.changePasswordForm.reset();
        }
        const dialogRef = this.dialog.open(AlertDialog, {
          minWidth: "30%",
          maxWidth: "30%",
          disableClose: true,
          data: { title: 'Information', message: res["message"], accept: 'Ok' }
        });
        dialogRef.afterClosed().subscribe(result => {

        });
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }

}
