<app-toolbar></app-toolbar>
<div class="container notoolbar">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center">
        <google-chart *ngIf="chart.data.length > 0" style="width: 50%;" [title]="chart.title" [options]="chart.options" [type]="chart.type"
            [data]="chart.data">
        </google-chart>
        <google-chart *ngIf="columnchart.data.length > 0" style="width: 50%;" [title]="columnchart.title" [options]="columnchart.options"
            [type]="columnchart.type" [data]="columnchart.data" [columns]="columnchart.column"></google-chart>
    </div>
    <div fxLayout="column" fxLayoutAlign="start start">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">

            <ng-container matColumnDef="product">
                <th mat-header-cell *matHeaderCellDef> Product </th>
                <td mat-cell *matCellDef="let element"> {{element.product}} </td>
            </ng-container>

            <ng-container matColumnDef="stock_left">
                <th mat-header-cell *matHeaderCellDef> Stock Left </th>
                <td mat-cell *matCellDef="let element"> {{element.stock_left}} {{element.unit}} </td>
            </ng-container>

            <ng-container matColumnDef="sale_today">
                <th mat-header-cell *matHeaderCellDef> Today Sale </th>
                <td mat-cell *matCellDef="let element"> {{element.sale_today}} {{element.unit}} </td>
            </ng-container>

            <ng-container matColumnDef="overall_sale">
                <th mat-header-cell *matHeaderCellDef> Overall Sale </th>
                <td mat-cell *matCellDef="let element"> {{element.overall_sale}} {{element.unit}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>