<app-toolbar></app-toolbar>
<mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChanged($event);">
    <mat-tab label="Sales Summary">
        <div class="container tab">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                <mat-form-field appearance="fill">
                    <mat-label>User</mat-label>
                    <input #vendorsummary matInput placeholder="" aria-label="User" [matAutocomplete]="auto"
                        [formControl]="userCtrl" />
                    <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="selectedOption($event.option.value, 'user', 'users', 'mv_user_id', 'name')">
                        <mat-option *ngIf="loading_options">
                            <mat-progress-spinner [mode]="'indeterminate'" [strokeWidth]="3" [diameter]="25">
                            </mat-progress-spinner>
                        </mat-option>
                        <mat-option *ngFor="let row of users" [value]="row.name">
                            {{row.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Choose a from date</mat-label>
                    <input matInput [matDatepicker]="pickerSummary" [(ngModel)]="from" />
                    <mat-datepicker-toggle matSuffix [for]="pickerSummary"></mat-datepicker-toggle>
                    <mat-datepicker #pickerSummary></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Choose a to date</mat-label>
                    <input matInput [matDatepicker]="pickerSummary2" [(ngModel)]="to" />
                    <mat-datepicker-toggle matSuffix [for]="pickerSummary2"></mat-datepicker-toggle>
                    <mat-datepicker #pickerSummary2></mat-datepicker>
                </mat-form-field>
                <button mat-raised-button color="primary" (click)="getSalesSummary()" [disabled]="loading">
                    <span *ngIf="loading == false" class="mat-display-1">Get Result</span>
                    <mat-progress-spinner *ngIf="loading == true" color="accent" mode="indeterminate" strokeWidth="4"
                        diameter="40" class="auto-margin"></mat-progress-spinner>
                </button>
                <button mat-raised-button color="warn" (click)="getSalesSummaryPDF()" [disabled]="loadingPDF">
                    <span *ngIf="loadingPDF == false">Download as PDF</span>
                    <mat-progress-spinner *ngIf="loadingPDF == true" color="accent" mode="indeterminate" strokeWidth="2"
                        diameter="20" class="auto-margin"></mat-progress-spinner>
                </button>
                <button mat-raised-button color="accent" (click)="getSalesSummaryExcel()" [disabled]="loadingExcel">
                    <span *ngIf="loadingExcel == false">Download as Excel</span>
                    <mat-progress-spinner *ngIf="loadingExcel == true" color="accent" mode="indeterminate"
                        strokeWidth="2" diameter="20" class="auto-margin"></mat-progress-spinner>
                </button>
            </div>
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef> Date </th>
                    <td mat-cell *matCellDef="let element"> {{element.date | date}}</td>
                    <td mat-footer-cell *matFooterCellDef>&nbsp;</td>
                </ng-container>

                <ng-container matColumnDef="order">
                    <th mat-header-cell *matHeaderCellDef> Bill# </th>
                    <td mat-cell *matCellDef="let element"> #{{element.order_id}}</td>
                    <td mat-footer-cell *matFooterCellDef>&nbsp;</td>
                </ng-container>

                <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef> Total </th>
                    <td mat-cell *matCellDef="let element"> ₹{{(element.total_amt - element.gst_amount)|number:'1.2-2'}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>₹{{getTotal('dataSource', 'total_amt') -
                        getTotal('dataSource', 'gst_amount')|number:'1.2-2'}}</td>
                </ng-container>

                <ng-container matColumnDef="gst">
                    <th mat-header-cell *matHeaderCellDef> GST </th>
                    <td mat-cell *matCellDef="let element"> ₹{{element.gst_amount|number:'1.2-2'}}</td>
                    <td mat-footer-cell *matFooterCellDef>₹{{getTotal('dataSource', 'gst_amount')|number:'1.2-2'}}</td>
                </ng-container>

                <ng-container matColumnDef="total_amt">
                    <th mat-header-cell *matHeaderCellDef> Grand Total </th>
                    <td mat-cell *matCellDef="let element"> ₹{{element.total_amt|number:'1.2-2'}}</td>
                    <td mat-footer-cell *matFooterCellDef>₹{{getTotal('dataSource', 'total_amt')|number:'1.2-2'}}</td>
                </ng-container>

                <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef> User </th>
                    <td mat-cell *matCellDef="let element"> {{element.user}}</td>
                    <td mat-footer-cell *matFooterCellDef>&nbsp;</td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef> Status </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.status == 1">Completed</span>
                        <span *ngIf="element.status == 0">Cancelled</span>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>&nbsp;</td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> Action </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-button (click)="showProducts(element)">
                            Info
                        </button>
                        <button *ngIf="element.status == 1" mat-button (click)="cancelInvoice(element)">
                            Cancel
                        </button>
                        <button mat-button (click)="generatePDF(element)" *ngIf="user > 0">
                            Print
                        </button>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>&nbsp;</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
            </table>
        </div>
    </mat-tab>
    <mat-tab label="Sales Detail">
        <div class="container tab">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                <mat-form-field appearance="fill">
                    <mat-label>User</mat-label>
                    <input #vendordetail matInput placeholder="" aria-label="User" [matAutocomplete]="auto"
                        [formControl]="userCtrl" />
                    <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="selectedOption($event.option.value, 'user', 'users', 'mv_user_id', 'name')">
                        <mat-option *ngIf="loading_options">
                            <mat-progress-spinner [mode]="'indeterminate'" [strokeWidth]="3" [diameter]="25">
                            </mat-progress-spinner>
                        </mat-option>
                        <mat-option *ngFor="let row of users" [value]="row.name">
                            {{row.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Product</mat-label>
                    <input matInput placeholder="" aria-label="State" [matAutocomplete]="autoproduct"
                        [formControl]="productvariantCtrl" />
                    <mat-autocomplete #autoproduct="matAutocomplete"
                        (optionSelected)="selectedOption($event.option.value, 'product', 'productvariants', 'mv_product_variant_id', 'name')">
                        <mat-option *ngIf="loading_options">
                            <mat-progress-spinner [mode]="'indeterminate'" [strokeWidth]="3" [diameter]="25">
                            </mat-progress-spinner>
                        </mat-option>
                        <mat-option *ngFor="let row of productvariants" [value]="row.name">
                            {{row.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Choose a from date</mat-label>
                    <input matInput [matDatepicker]="pickerDetail" [(ngModel)]="from" />
                    <mat-datepicker-toggle matSuffix [for]="pickerDetail"></mat-datepicker-toggle>
                    <mat-datepicker #pickerDetail></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Choose a to date</mat-label>
                    <input matInput [matDatepicker]="pickerDetail2" [(ngModel)]="to" />
                    <mat-datepicker-toggle matSuffix [for]="pickerDetail2"></mat-datepicker-toggle>
                    <mat-datepicker #pickerDetail2></mat-datepicker>
                </mat-form-field>
                <button mat-raised-button color="primary" (click)="getSalesDetail()" [disabled]="loading">
                    <span *ngIf="loading == false" class="mat-display-1">Get Result</span>
                    <mat-progress-spinner *ngIf="loading == true" color="accent" mode="indeterminate" strokeWidth="4"
                        diameter="40" class="auto-margin"></mat-progress-spinner>
                </button>
                <button mat-raised-button color="warn" (click)="getSalesDetailPDF()" [disabled]="loadingPDF">
                    <span *ngIf="loadingPDF == false">Download as PDF</span>
                    <mat-progress-spinner *ngIf="loadingPDF == true" color="accent" mode="indeterminate" strokeWidth="2"
                        diameter="20" class="auto-margin"></mat-progress-spinner>
                </button>
                <button mat-raised-button color="accent" (click)="getSalesDetailExcel()" [disabled]="loadingExcel">
                    <span *ngIf="loadingExcel == false">Download as Excel</span>
                    <mat-progress-spinner *ngIf="loadingExcel == true" color="accent" mode="indeterminate"
                        strokeWidth="2" diameter="20" class="auto-margin"></mat-progress-spinner>
                </button>
            </div>

            <table mat-table [dataSource]="dataSourceDetail" class="mat-elevation-z8" style="width: 100%;">
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef> Date </th>
                    <td mat-cell *matCellDef="let element"> {{element.date | date}}</td>
                    <td mat-footer-cell *matFooterCellDef>&nbsp;</td>
                </ng-container>

                <ng-container matColumnDef="order">
                    <th mat-header-cell *matHeaderCellDef> Bill# </th>
                    <td mat-cell *matCellDef="let element"> #{{element.order_id}}</td>
                    <td mat-footer-cell *matFooterCellDef>&nbsp;</td>
                </ng-container>

                <ng-container matColumnDef="product">
                    <th mat-header-cell *matHeaderCellDef> Product </th>
                    <td mat-cell *matCellDef="let element"> {{element.product}}</td>
                    <td mat-footer-cell *matFooterCellDef>&nbsp;</td>
                </ng-container>

                <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef> Qty </th>
                    <td mat-cell *matCellDef="let element"> {{element.quantity}}</td>
                    <td mat-footer-cell *matFooterCellDef>&nbsp;</td>
                </ng-container>

                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef> Price </th>
                    <td mat-cell *matCellDef="let element"> ₹{{element.price|number:'1.2-2'}}</td>
                    <td mat-footer-cell *matFooterCellDef>&nbsp;</td>
                </ng-container>

                <ng-container matColumnDef="gst">
                    <th mat-header-cell *matHeaderCellDef> GST </th>
                    <td mat-cell *matCellDef="let element"> ₹{{getGST(element.total,
                        element.gst_percent)|number:'1.2-2'}}</td>
                    <td mat-footer-cell *matFooterCellDef>₹{{getTotal('dataSourceDetail',
                        'gst_percent')|number:'1.2-2'}}</td>
                </ng-container>

                <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef> Total </th>
                    <td mat-cell *matCellDef="let element"> ₹{{element.total + (getGST(element.total,
                        element.gst_percent))|number:'1.2-2'}}</td>
                    <td mat-footer-cell *matFooterCellDef>₹{{getTotal('dataSourceDetail',
                        'total_with_gst')|number:'1.2-2'}}</td>
                </ng-container>

                <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef> User </th>
                    <td mat-cell *matCellDef="let element">{{element.user}}</td>
                    <td mat-footer-cell *matFooterCellDef>&nbsp;</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsDetail"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsDetail;"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumnsDetail"></tr>
            </table>
        </div>
    </mat-tab>
    <mat-tab label="Stock Position" *ngIf="is_inventory_based == '1'">
        <mat-progress-bar *ngIf="loading == true" mode="indeterminate" color="warn"></mat-progress-bar>
        <div class="container tab">
            <div fxLayout="row" fxLayoutAlign="end stretch" fxLayoutGap="30px" class="padding-bottom-20">
                <button mat-raised-button color="primary" (click)="getStockPosition()"
                    [disabled]="loading">Refresh</button>
                <button mat-raised-button color="warn" (click)="getStockPDF()" [disabled]="loadingPDF">
                    <span *ngIf="loadingPDF == false">Download as PDF</span>
                    <mat-progress-spinner *ngIf="loadingPDF == true" color="accent" mode="indeterminate" strokeWidth="2"
                        diameter="20" class="auto-margin"></mat-progress-spinner>
                </button>
                <button mat-raised-button color="accent" (click)="getStockExcel()" [disabled]="loadingExcel">
                    <span *ngIf="loadingExcel == false">Download as Excel</span>
                    <mat-progress-spinner *ngIf="loadingExcel == true" color="accent" mode="indeterminate"
                        strokeWidth="2" diameter="20" class="auto-margin"></mat-progress-spinner>
                </button>
            </div>
            <div fxLayout="column" fxLayoutAlign="end stretch" fxLayoutGap="30px" *ngIf="dataSourceStock.length > 0">
                <table mat-table [dataSource]="dataSourceStock" class="mat-elevation-z8">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> Product </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="stock_in_hand">
                        <th mat-header-cell *matHeaderCellDef> Stock Left </th>
                        <td mat-cell *matCellDef="let element"> {{element.stock_in_hand}} {{element.unit}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsStock"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsStock;"></tr>
                </table>
            </div>
            <div fxLayout="row" fxLayoutAlign="center stretch" fxLayoutGap="30px"
                *ngIf="dataSourceStock.length == 0 && !loading">
                <div class="mat-display-2" style="opacity: 0.4;">
                    No Results Found
                </div>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>