import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Ecommerce_Config } from '../Ecommerce_Config';
import { ImagePreviewDialog, ConfirmDialog } from '../dialog/dialog.component';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, TableService } from '@syncfusion/ej2-angular-richtexteditor';
import { FileValidator } from 'ngx-material-file-input';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  category_param: string = null;
  more_option: string = '';
  sort_option: string = 'name-asc';
  search_qry: string = '';
  page_length: number = 110;
  pageind: number = 0;
  loading: boolean = false;
  more_action_ids: string[] = [];
  items: any = [];
  img_url: string = Ecommerce_Config.img_url;
  constructor(public route: ActivatedRoute, private _snackBar: MatSnackBar, private httpClient: HttpClient, public dialog: MatDialog) {
    if (this.route.snapshot.params.category) {
      this.category_param = this.route.snapshot.params.category;
    }
    Ecommerce_Config["page_title"] = 'Product';
  }

  getProductData(ev) {
    this.getProduct(ev.pageIndex);
  }
  getProduct(page) {
    this.more_option = '';
    this.more_action_ids = [];
    this.pageind = page;
    page = page + 1;
    this.loading = true;
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    var query = null;
    if (this.search_qry != '') {
      query = this.search_qry;
      this.category_param = null;
    }
    this.httpClient.get(Ecommerce_Config["api_url"] + 'product/' + this.category_param + '/' + query + '/' + this.sort_option + '/' + page, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.items = res["data"];
          this.page_length = res["count"];
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }
  toggleMoreOption(ev, code) {
    var index = (this.more_action_ids).indexOf(code);
    if (ev.checked == true && index < 0) {
      (this.more_action_ids).push(code);
    } else {
      (this.more_action_ids).splice(index, 1);
    }
    if (this.more_action_ids.length == 0) {
      this.more_option = '';
    }
  }
  checkedItem(code) {
    return (this.more_action_ids).indexOf(code) > -1;
  }
  doMoreOption() {
    var msg = '';
    switch (this.more_option) {
      case 'active':
        msg = 'Are you sure? Do you want to active these ' + this.more_action_ids.length + ' products?';
        break;
      case 'deactive':
        msg = 'Are you sure? Do you want to deactive these ' + this.more_action_ids.length + ' products?';
        break;
      default:
        break;
    }
    const dialogRef = this.dialog.open(ConfirmDialog, {
      minWidth: "30%",
      maxWidth: "30%",
      disableClose: true,
      data: { title: 'Confirmation', message: msg, cancel: 'No', accept: 'Yes' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result == true) {
        this.loading = true;
        var token = sessionStorage.getItem("auth_token");
        var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
        this.httpClient.put(Ecommerce_Config["api_url"] + 'productmoreoption/' + this.more_option, { codes: this.more_action_ids }, { headers: header }).subscribe(
          (res) => {
            this.loading = false;
            this._snackBar.open(res["message"], '', {
              duration: 2000,
            });
            if (res["code"] == 200) {
              this.more_action_ids = [];
              this.more_option = '';
              this.getProduct(this.pageind);
            }
          },
          (error) => {
            this.loading = false;
            this._snackBar.open(error.error.message, '', {
              duration: 2000,
            });
          });
      }
    });
  }
  changeStatus(ev, code) {
    var msg = '';
    if (ev.checked == true) {
      msg = 'Are you sure? Do you want to enable this product?';
    } else {
      msg = 'Are you sure? Do you want to disable this product?';
    }
    const dialogRef = this.dialog.open(ConfirmDialog, {
      minWidth: "30%",
      maxWidth: "30%",
      disableClose: true,
      data: { title: 'Confirmation', message: msg, cancel: 'No', accept: 'Yes' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result == true) {
        this.loading = true;
        var token = sessionStorage.getItem("auth_token");
        var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
        this.httpClient.put(Ecommerce_Config["api_url"] + 'toggleproductstate/' + code, { isactive: ev.checked }, { headers: header }).subscribe(
          (res) => {
            this.loading = false;
            this._snackBar.open(res["message"], '', {
              duration: 2000,
            });
            if (res["code"] != 200) {
              (this.items).forEach(val => {
                if (val.code == code) {
                  val.status = !val.status;
                  return false;
                }
              });
            }
          },
          (error) => {
            this.loading = false;
            this._snackBar.open(error.error.message, '', {
              duration: 2000,
            });
          });
      } else {
        (this.items).forEach(val => {
          if (val.code == code) {
            val.status = !val.status;
            return false;
          }
        });
      }
    });
  }
  previewImage(ttl, path) {
    const dialogRef = this.dialog.open(ImagePreviewDialog, {
      minWidth: "30%",
      maxWidth: "30%",
      disableClose: true,
      data: { title: ttl, path: path, accept: 'Close' }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }
  ngOnInit(): void {
    this.getProduct(0);
  }
}

@Component({
  selector: 'app-add-product',
  templateUrl: './add.product.component.html',
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, TableService],
  styleUrls: ['./product.component.css']
})
export class AddProductComponent implements OnInit {
  public tools: object = {
    items: [
      'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
      'FontSize', 'FontColor', 'BackgroundColor', '|',
      'Undo', 'Redo', '|',
      'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
      'CreateLink', 'CreateTable']
  };
  loading = false;
  productForm: FormGroup;
  categoryCtrl: FormControl = new FormControl();
  categories: any = [];
  img_path: string = '';
  loading_options: boolean = false;
  constructor(private _snackBar: MatSnackBar, private router: Router, private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.productForm = new FormGroup({
      'product_name': new FormControl('', [Validators.required]),
      'product_description': new FormControl(''),
      'product_category': new FormControl('', Validators.required),
      'product_image': new FormControl(undefined, [FileValidator.maxContentSize(Ecommerce_Config.max_file_size)]),
      'isactive': new FormControl(true, [Validators.required])
    });
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.categoryCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.categories = [];
          this.loading_options = true;
        }),
        switchMap(srch => this.httpClient.get<any>(Ecommerce_Config["api_url"] + 'category_autocomplete/' + srch, { headers: header })
          .pipe(
            finalize(() => {
              this.loading_options = false
            }),
          )
        )
      )
      .subscribe(res => {
        if (res["code"] == 200) {
          this.categories = res["data"];
        } else {
          this.categories = [];
        }
      });
  }
  selectedOption(val, param, array, idparam, matchval) {
    this[array].forEach(v => {
      if (v[matchval] == val) {
        this.productForm.patchValue({
          [param]: v[idparam]
        });
        return false;
      }
    });
  }
  addProduct() {
    if (this.productForm.invalid) {
      return;
    }
    this.loading = true;
    var formData = new FormData();
    var active = '1';
    if (this.productForm.value.isactive == false) {
      active = '0';
    }
    formData.append('product_name', this.productForm.value.product_name)
    formData.append('isactive', active);
    formData.append('product_description', this.productForm.value.product_description);
    formData.append('product_category', this.productForm.value.product_category);
    if (this.productForm.value.product_image != null && typeof this.productForm.value.product_image['_files'] != 'undefined') {
      formData.append('product_image', this.productForm.value.product_image['_files'][0]);
    } else {
      formData.append('product_image', '');
    }
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.httpClient.post(Ecommerce_Config["api_url"] + 'product', formData, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.router.navigateByUrl('/product');
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }
  removeImage() {
    this.productForm.patchValue({
      product_image: null
    });
    this.img_path = '';
  }
  fileProgress(event, param) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this[param] = e.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }
}

@Component({
  selector: 'app-update-product',
  templateUrl: './update.product.component.html',
  styleUrls: ['./product.component.css']
})
export class UpdateProductComponent implements OnInit {
  product_code: string = '';
  public tools: object = {
    items: [
      'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
      'FontSize', 'FontColor', 'BackgroundColor', '|',
      'Undo', 'Redo', '|',
      'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
      'CreateLink', 'CreateTable']
  };
  loading = false;
  productForm: FormGroup;
  categoryCtrl: FormControl = new FormControl();
  categories: any = [];
  loading_options: boolean = false;
  img_path: string = '';
  img_url:string =  Ecommerce_Config.img_url;
  constructor(public route: ActivatedRoute, private _snackBar: MatSnackBar, private httpClient: HttpClient, private router: Router) {
    this.product_code = this.route.snapshot.params.code;
    this.productForm = new FormGroup({
      'product_name': new FormControl('', [Validators.required]),
      'product_description': new FormControl(''),
      'product_category': new FormControl('', Validators.required),
      'product_image': new FormControl(undefined, [FileValidator.maxContentSize(Ecommerce_Config.max_file_size)]),
      'isactive': new FormControl(true, [Validators.required])
    });
  }
  getProduct() {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.httpClient.get(Ecommerce_Config["api_url"] + 'product/' + this.product_code, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.productForm.patchValue({
            product_name: res["data"]["name"],
            product_description: res["data"]["description"],
            product_category: res["data"]["mv_category_id"],
            isactive: res["data"]["status"]
          });
          this.img_path = res["data"]["img_path"];
          this.categoryCtrl.setValue(res["data"]["category"]);
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }
  updateProduct() {
    if (this.productForm.invalid) {
      return;
    }
    this.loading = true;
    var formData = new FormData();
    var active = '1';
    if (this.productForm.value.isactive == false) {
      active = '0';
    }
    formData.append('product_name', this.productForm.value.product_name);
    formData.append('isactive', active);
    formData.append('product_description', this.productForm.value.product_description);
    formData.append('product_category', this.productForm.value.product_category);
    formData.append('img_path', this.img_path);
    if (this.productForm.value.product_image != null && typeof this.productForm.value.product_image['_files'] != 'undefined') {
      formData.append('product_image', this.productForm.value.product_image['_files'][0]);
    } else {
      formData.append('product_image', '');
    }
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.httpClient.put(Ecommerce_Config["api_url"] + 'product/' + this.product_code, formData, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.router.navigateByUrl('/product');
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }
  ngOnInit(): void {
    this.getProduct();
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.categoryCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.categories = [];
          this.loading_options = true;
        }),
        switchMap(srch => this.httpClient.get<any>(Ecommerce_Config["api_url"] + 'category_autocomplete/' + srch, { headers: header })
          .pipe(
            finalize(() => {
              this.loading_options = false
            }),
          )
        )
      )
      .subscribe(res => {
        if (res["code"] == 200) {
          this.categories = res["data"];
        } else {
          this.categories = [];
        }
      });
  }
  selectedOption(val, param, array, idparam, matchval) {
    this[array].forEach(v => {
      if (v[matchval] == val) {
        this.productForm.patchValue({
          [param]: v[idparam]
        });
        return false;
      }
    });
  }
  removeImage() {
    this.productForm.patchValue({
      product_image: null
    }); this.img_path = '';
  }
  fileProgress(event, param) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this[param] = e.target.result;
        this.img_url = '';
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }
}