<div class="container notoolbar">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" fxLayout.xs="column">
        <button mat-raised-button routerLink="/category">Back to category</button>
    </div>
    <div fxLayout="row" fxLayoutAlign="center stretch" fxLayoutGap="20px" fxLayout.xs="column">
        <form [formGroup]="categoryForm" autocomplete="off" (ngSubmit)="addCategory()" fxLayout="column"
            fxLayoutAlign="start stretch" fxLayoutGap="10px" style="width: 30%;">
            <mat-form-field appearance="legacy">
                <mat-label>Category Name</mat-label>
                <input matInput type="text" placeholder="" formControlName="category_name" />
                <mat-error *ngIf="categoryForm.get('category_name').hasError('required')">
                    Category name is required
                </mat-error>
            </mat-form-field>
            <mat-checkbox formControlName="isactive"> Active</mat-checkbox>
            <button [disabled]="!categoryForm.valid || loading" mat-raised-button color="primary" type="submit">
                <span *ngIf="loading == false">Add Category</span>
                <mat-progress-spinner *ngIf="loading == true" color="accent" mode="indeterminate" strokeWidth="4"
                    diameter="40" class="auto-margin"></mat-progress-spinner>
            </button>
        </form>
    </div>
</div>