<app-toolbar></app-toolbar>
<mat-progress-bar *ngIf="loading == true" mode="indeterminate" color="warn"></mat-progress-bar>
<div class="container notoolbar">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="50px" style="height: 90%;">
        <mat-card style="width: 40%;" fxLayout="column" fxLayoutAlign="stretch stretch">
            <form [formGroup]="changePasswordForm" autocomplete="off" (ngSubmit)="changePassword()" style="width: 100%;"
                fxLayout="column" fxLayoutAlign="stretch stretch">
                <mat-form-field appearance="legacy">
                    <mat-label>Old Password</mat-label>
                    <input matInput type="password" placeholder="" formControlName="old_password" />
                    <mat-error *ngIf="changePasswordForm.get('old_password').hasError('required')">
                        Old Password is required
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="legacy">
                    <mat-label>New Password</mat-label>
                    <input matInput type="password" placeholder="" formControlName="new_password" />
                    <mat-error *ngIf="changePasswordForm.get('new_password').hasError('required')">
                        New Password is required
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="legacy">
                    <mat-label>Re-type new password</mat-label>
                    <input matInput type="password" placeholder="" formControlName="confirm_new_password" />
                    <mat-error *ngIf="changePasswordForm.get('confirm_new_password').hasError('required')">
                        Re-type new password is required
                    </mat-error>
                    <mat-hint
                        *ngIf="changePasswordForm.errors?.mismatch && !changePasswordForm.get('confirm_new_password').hasError('required')">
                        <span class="mat-error">Password mismatch</span>
                    </mat-hint>
                </mat-form-field>
                <button [disabled]="!changePasswordForm.valid || loading" mat-raised-button color="primary"
                    type="submit">Submit
                </button>
            </form>
        </mat-card>
    </div>
</div>