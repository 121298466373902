<h1 mat-dialog-title>Quick Add {{data.label}}</h1>
<div mat-dialog-content>
    <form [formGroup]="userForm" autocomplete="off" (ngSubmit)="addUser()" fxLayout="row" fxLayoutAlign="start start"
        fxLayoutGap="10px" style="width: 100%;">
        <div fxLayout="column" fxLayoutAlign="stretch stretch" fxLayoutGap="10px" fxFlex="30">
            <mat-form-field appearance="legacy">
                <mat-label>Name</mat-label>
                <input matInput type="text" placeholder="" formControlName="name" />
                <mat-error *ngIf="userForm.get('name').hasError('required')">
                    Name is required
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="legacy">
                <mat-label>Email</mat-label>
                <input matInput type="text" placeholder="" formControlName="email" />
                <mat-error *ngIf="userForm.get('email').hasError('required')">
                    Email is required
                </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px" fxFlex="30">
            <mat-form-field appearance="legacy">
                <mat-label>Mobile</mat-label>
                <input matInput type="text" placeholder="" formControlName="mobile" />
                <mat-error *ngIf="userForm.get('mobile').hasError('required')">
                    Mobile is required
                </mat-error>
            </mat-form-field>
            <mat-checkbox formControlName="isactive"> Active</mat-checkbox>
        </div>
        <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px" fxFlex="30">
            <mat-form-field appearance="legacy">
                <mat-label>GST</mat-label>
                <input matInput type="text" placeholder="" formControlName="gst" />
                <mat-error *ngIf="userForm.get('gst').hasError('required')">
                    GST is required
                </mat-error>
            </mat-form-field>
            <button [disabled]="!userForm.valid || loading" mat-raised-button color="primary" type="submit">
                <span *ngIf="loading == false">Add User</span>
                <mat-progress-spinner *ngIf="loading == true" color="accent" mode="indeterminate" strokeWidth="4"
                    diameter="40" class="auto-margin"></mat-progress-spinner>
            </button>
        </div>
    </form>
</div>
<div mat-dialog-actionss align="end">
    <button mat-button [mat-dialog-close]="false">Cancel</button>
</div>