<app-toolbar></app-toolbar>
<div *ngIf="route.children.length === 0">
    <mat-progress-bar *ngIf="loading == true" mode="indeterminate" color="warn"></mat-progress-bar>
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" class="toolbar">
        <div class="action-lbl">
            Actions:
        </div>
        <button mat-raised-button color="accent" routerLink="/sale/add">
            Add Sale
        </button>
        <button mat-raised-button (click)="getSale(pageind)">
            Refresh
        </button>
        <mat-form-field [floatLabel]="'never'">
            <mat-label>Sort by</mat-label>
            <mat-select [(ngModel)]="sort_option" (selectionChange)="getSale(pageind)" [disableOptionCentering]="true">
                <mat-option value="created_at-desc">
                    Date DESC
                </mat-option>
                <mat-option value="created_at-asc">
                    Date ASC
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field [floatLabel]="'never'">
            <input type="text" matInput placeholder="Search" [(ngModel)]="search_qry" (input)="getSale(0)" />
        </mat-form-field>
        <mat-paginator [length]="page_length" [pageSize]="20" [pageIndex]="pageind" (page)="getSaleData($event)"
            [hidePageSize]="true">
        </mat-paginator>
    </mat-toolbar-row>
    <div class="container">
        <mat-list>
            <mat-list-item *ngFor="let item of items; let lastItem = last">
                <div mat-line> {{item.type|uppercase}} with {{item.user_name}} on
                    {{item.created_at|date:'medium'}} for ₹{{item.grand_total|number:'1.2-2'}}</div>
                <div mat-line> {{item.remark}} </div>
                <mat-action-list>
                    <button mat-button (click)="showProducts(item)">
                        Info
                    </button>
                </mat-action-list>
                <mat-divider *ngIf="!lastItem"></mat-divider>
            </mat-list-item>
        </mat-list>
    </div>
</div>
<router-outlet></router-outlet>