import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Ecommerce_Config } from '../Ecommerce_Config';
import { ActivatedRoute } from '@angular/router'
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { InventoryDetail, BillPreviewDialog, ConfirmDialog } from '../dialog/dialog.component';
import { FormControl } from '@angular/forms';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
const pdfMake = require('pdfmake/build/pdfmake.js');
import * as pdfFonts from "pdfmake/build/vfs_fonts";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  @ViewChild('vendorsummary', { static: false }) vendorsummary: ElementRef;
  @ViewChild('vendordetail', { static: false }) vendordetail: ElementRef;
  loading: boolean = false;
  date: Date = new Date();
  from: Date = new Date();
  to: Date = new Date();
  user: Number = 0;
  product: Number = 0;
  displayedColumns: string[] = ['date', 'order', 'total', 'gst', 'total_amt', 'user', 'status', 'action'];
  displayedColumnsDetail: string[] = ['date', 'order', 'product', 'quantity', 'price', 'gst', 'total', 'user'];
  dataSource: any = [];
  dataSourceDetail: any = [];
  displayedColumnsStock: string[] = ['name', 'stock_in_hand'];
  dataSourceStock: any = [];
  users: any = [];
  productvariants: any = [];
  loading_options = false;
  loadingExcel = false;
  loadingPDF = false;
  selectedTabIndex: number = 0;
  receiptPDF: any = '';
  storename: string = sessionStorage.getItem("store_name");
  phone: string = sessionStorage.getItem("phone");
  email: string = sessionStorage.getItem("email");
  address1: string = sessionStorage.getItem("address1");
  address2: string = sessionStorage.getItem("address2");
  gst: string = sessionStorage.getItem("gst");
  logo: string = sessionStorage.getItem("logo");
  is_inventory_based: string = sessionStorage.getItem("is_inventory_based");
  image_url: string = Ecommerce_Config['img_url'];
  userCtrl: FormControl = new FormControl();
  processing: boolean = false;
  processing_code: boolean = false;
  productvariantCtrl: FormControl = new FormControl();

  constructor(public route: ActivatedRoute, private _snackBar: MatSnackBar, private datePipe: DatePipe, public sanitizer: DomSanitizer, private httpClient: HttpClient, public dialog: MatDialog, private cdRef: ChangeDetectorRef) {
    Ecommerce_Config["page_title"] = 'Sale & Stock Report';
  }

  onTabChanged(ev) {
    if (ev.index == 2) {
      this.getStockPosition();
    } else if (ev.index == 0) {
      setTimeout(() => {
        this.userCtrl.setValue('');
        this.user = 0;
        this.vendorsummary.nativeElement.focus();
        this.cdRef.detectChanges();
      }, 100);
    } else if (ev.index == 1) {
      setTimeout(() => {
        this.userCtrl.setValue('');
        this.user = 0;
        this.vendordetail.nativeElement.focus();
        this.cdRef.detectChanges();
      }, 100);
    }
  }

  ngOnInit(): void {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.userCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.users = [];
          this.loading_options = true;
        }),
        switchMap(srch => this.httpClient.get<any>(Ecommerce_Config["api_url"] + 'vendorncustomer_autocomplete/' + srch, { headers: header })
          .pipe(
            finalize(() => {
              this.loading_options = false
            }),
          )
        )
      )
      .subscribe(res => {
        if (res["code"] == 200) {
          this.users = res["data"];
        } else {
          this.users = [];
        }
      });
    this.productvariantCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.productvariants = [];
          this.loading_options = true;
        }),
        switchMap(srch => this.httpClient.get<any>(Ecommerce_Config["api_url"] + 'productvariant_autocomplete/' + srch, { headers: header })
          .pipe(
            finalize(() => {
              this.loading_options = false
            }),
          )
        )
      )
      .subscribe(res => {
        if (res["code"] == 200) {
          this.productvariants = res["data"];
        } else {
          this.productvariants = [];
        }
      });
    setTimeout(() => {
      this.vendorsummary.nativeElement.focus();
      this.cdRef.detectChanges();
    }, 100);
  }

  getStockPosition() {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.loading = true;
    this.httpClient.get(Ecommerce_Config["api_url"] + 'stock_position', { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.dataSourceStock = res["data"];
        } else {
          this.dataSourceStock = [];
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.dataSourceStock = [];
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }

  showProducts(row) {
    row.mv_inventory_id = row.order_id;
    const dialogRef = this.dialog.open(InventoryDetail, {
      minWidth: "40%",
      maxWidth: "40%",
      disableClose: true,
      data: row
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

  cancelInvoice(row) {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      minWidth: "30%",
      maxWidth: "30%",
      disableClose: true,
      data: { title: 'Confirmation', message: 'Are you sure? Do you want to cancel this invoice?', cancel: 'No', accept: 'Yes' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result == true) {
        this.processing_code = row.code;
        this.processing = true;
        var token = sessionStorage.getItem("auth_token");
        var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
        this.httpClient.get(Ecommerce_Config["api_url"] + 'sale_cancel/' + row.code, { headers: header }).subscribe(
          (res) => {
            this.processing = false;
            this._snackBar.open(res['message'], '', {
              duration: 2000,
            });
            if (res["code"] == 200) {
              this.getSalesSummary();
            }
          },
          (error) => {
            this.processing = false;
            this._snackBar.open(error.error.message, '', {
              duration: 2000,
            });
          });
      }
    });
  }

  getSalesSummary() {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.loading = true;
    this.httpClient.get(Ecommerce_Config["api_url"] + 'sales_summary/' + moment(this.from).format('YYYY-MM-DD') + '/' + moment(this.to).format('YYYY-MM-DD') + '/' + this.user, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.dataSource = res["data"];
        } else {
          this.dataSource = [];
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }

  getSalesSummaryExcel() {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.loadingExcel = true;
    this.httpClient.get(Ecommerce_Config["api_url"] + 'sales_summary_excel/' + moment(this.from).format('YYYY-MM-DD') + '/' + moment(this.to).format('YYYY-MM-DD') + '/' + this.user, { headers: header }).subscribe(
      (res) => {
        this.loadingExcel = false;
        if (res["code"] == 200) {
          window.open(Ecommerce_Config['img_url'] + res['data'], '_blank');
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loadingExcel = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }

  generatePDF(bill) {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.httpClient.get(Ecommerce_Config["api_url"] + 'inventorylines/' + bill.code, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          var record = res["data"];
          var product_lines = [];
          var line = 1;
          var company_name = '';
          var company_address = '';
          var company_gst = '';
          var company_phone = '';
          var found = false;
          (record).forEach(element => {
            var margin = [0, 0, 0, 5];
            if (line == record.length) {
              margin = [0, 0, 0, 5];
            }
            (product_lines).push({
              columns: [{ text: line, width: 40, alignment: 'left', fontSize: 10, margin: margin },
              { text: element.name, alignment: 'left', fontSize: 10, margin: margin },
              { text: element.quantity + element.unit + 'X ₹' + parseFloat(element.price).toFixed(2), alignment: 'left', fontSize: 10, margin: margin },
              { text: '₹' + parseFloat(element.total).toFixed(2), alignment: 'right', width: 60, fontSize: 10, margin: margin }]
            });
            line++;
          });
          (this.users).forEach(element => {
            if (!found && element.mv_user_id == bill.user_id) {
              found = true;
              company_name = element.company_name;
              company_address = element.address;
              company_gst = element.gst;
              company_phone = element.mobile;
            }
          });
          this.getBase64Image(this.image_url + this.logo, (base64) => {
            let docDefinition = {
              pageSize: {
                width: 288,
                height: 'auto'
              },
              pageMargins: [10, 20, 10, 20],
              content: [
                {
                  columns: [
                    {
                      text: 'Bill No.: #' + bill.order_id,
                      fontSize: 16,
                      alignment: 'left',
                      bold: true
                    },
                    {
                      width: 130,
                      alignment: 'right',
                      image: base64
                    },
                  ]
                },
                { text: 'Date: ' + this.datePipe.transform(new Date(), 'dd-MMM-yyyy hh:mm a'), bold: true, fontSize: 10, alignment: 'left', margin: [0, 0, 0, 5] },
                {
                  canvas:
                    [
                      {
                        type: 'line',
                        x1: 0, y1: 0,
                        x2: 268, y2: 0,
                        lineWidth: 1,
                      },
                    ]
                },
                {
                  columns: [
                    { text: 'Store Details:', bold: true, fontSize: 10, alignment: 'left', margin: [0, 5, 0, 0] },
                    { text: 'Customer Details:', bold: true, fontSize: 10, alignment: 'right', margin: [0, 5, 0, 0] },
                  ]
                },
                {
                  columns: [
                    { text: this.storename, alignment: 'left', fontSize: 10 },
                    { text: company_name, alignment: 'right', fontSize: 10 },
                  ]
                },
                {
                  columns: [
                    { text: (this.address1 + ', ' + this.address2), alignment: 'left', fontSize: 10 },
                    { text: company_address, alignment: 'right', fontSize: 10 },
                  ]
                },
                {
                  columns: [
                    { text: 'Ph: ' + this.phone, alignment: 'left', fontSize: 10 },
                    { text: 'Ph: ' + company_phone, alignment: 'right', fontSize: 10 },
                  ]
                },
                {
                  columns: [
                    { text: 'GST: ' + this.gst, alignment: 'left', margin: [0, 0, 0, 5], fontSize: 10 },
                    { text: 'GST: ' + company_gst, alignment: 'right', margin: [0, 0, 0, 5], fontSize: 10 },
                  ]
                },
                {
                  canvas:
                    [
                      {
                        type: 'line',
                        x1: 0, y1: 0,
                        x2: 268, y2: 0,
                        lineWidth: 1,
                      },
                    ]
                },
                {
                  columns: [
                    { text: 'Sl. No.', width: 40, alignment: 'left', margin: [0, 5, 0, 5], fontSize: 10, bold: true },
                    { text: 'Product', alignment: 'left', margin: [0, 5, 0, 5], fontSize: 10, bold: true },
                    { text: 'Quantity', alignment: 'left', margin: [0, 5, 0, 5], fontSize: 10, bold: true },
                    { text: 'Total', alignment: 'right', width: 60, margin: [0, 5, 0, 5], fontSize: 10, bold: true },
                  ]
                },
                product_lines,
                {
                  canvas:
                    [
                      {
                        type: 'line',
                        x1: 0, y1: 0,
                        x2: 268, y2: 0,
                        lineWidth: 1,
                      },
                    ]
                },
                {
                  columns: [
                    { text: 'GST' + (bill.gst_percent > 0 ? (bill.gst_percent + '%') : ''), alignment: 'right', margin: [0, 5, 0, 0], fontSize: 14, bold: true },
                    { text: '₹' + (bill.gst_amount > 0 ? parseFloat(bill.gst_amount).toFixed(2) : '0.00'), alignment: 'right', width: 100, margin: [0, 5, 0, 0], fontSize: 14, bold: true },
                  ]
                },
                {
                  columns: [
                    { text: 'Grand Toatal', alignment: 'right', margin: [0, 5, 0, 0], fontSize: 14, bold: true },
                    { text: '₹' + parseFloat(bill.total_amt).toFixed(2), alignment: 'right', width: 100, margin: [0, 5, 0, 0], fontSize: 14, bold: true },
                  ]
                },
                { text: '\n' },
                { text: '\n' },
                { text: 'Thank you! Visit again!!', alignment: 'center', fontSize: 9 },
                { text: 'Powered by https://www.mdmacrm.com', alignment: 'center', fontSize: 9 }
              ],
              styles: {
                tableExample: {
                  margin: [5, 10, 5, 10],
                }
              }
            };
            const pdfDocGenerator = pdfMake.createPdf(docDefinition);
            pdfDocGenerator.getDataUrl((dataUrl: any) => {
              this.receiptPDF = this.sanitizer.bypassSecurityTrustResourceUrl(dataUrl);
              const dialogRef = this.dialog.open(BillPreviewDialog, {
                minWidth: "30%",
                maxWidth: "30%",
                disableClose: true,
                data: { title: 'Bill', path: this.receiptPDF, accept: 'Close' }
              });
              dialogRef.afterClosed().subscribe(result => {
                console.log(result);
              });
            });
          });
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }

  async getBase64Image(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  getSalesSummaryPDF() {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.loadingPDF = true;
    this.httpClient.get(Ecommerce_Config["api_url"] + 'sales_summary_pdf/' + moment(this.from).format('YYYY-MM-DD') + '/' + moment(this.to).format('YYYY-MM-DD') + '/' + this.user, { headers: header }).subscribe(
      (res) => {
        this.loadingPDF = false;
        if (res["code"] == 200) {
          window.open(Ecommerce_Config['img_url'] + res['data'], '_blank');
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loadingPDF = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }

  getSalesDetail() {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.loading = true;
    this.httpClient.get(Ecommerce_Config["api_url"] + 'sales_detail/' + moment(this.from).format('YYYY-MM-DD') + '/' + moment(this.to).format('YYYY-MM-DD') + '/' + this.user + '/' + this.product, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.dataSourceDetail = res["data"];
        } else {
          this.dataSourceDetail = [];
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.dataSourceDetail = [];
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }

  getSalesDetailExcel() {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.loadingExcel = true;
    this.httpClient.get(Ecommerce_Config["api_url"] + 'sales_detail_excel/' + moment(this.from).format('YYYY-MM-DD') + '/' + moment(this.to).format('YYYY-MM-DD') + '/' + this.user + '/' + this.product, { headers: header }).subscribe(
      (res) => {
        this.loadingExcel = false;
        if (res["code"] == 200) {
          window.open(Ecommerce_Config['img_url'] + res['data'], '_blank');
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loadingExcel = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }

  getSalesDetailPDF() {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.loadingPDF = true;
    this.httpClient.get(Ecommerce_Config["api_url"] + 'sales_detail_pdf/' + moment(this.from).format('YYYY-MM-DD') + '/' + moment(this.to).format('YYYY-MM-DD') + '/' + this.user + '/' + this.product, { headers: header }).subscribe(
      (res) => {
        this.loadingPDF = false;
        if (res["code"] == 200) {
          window.open(Ecommerce_Config['img_url'] + res['data'], '_blank');
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loadingPDF = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }

  getTotal(data, type) {
    var total = 0;
    if (type != 'gst_percent' && type != 'total_with_gst') {
      this[data].forEach(v => {
        total = total + v[type];
      });
    } else {
      if (type == 'gst_percent') {
        this[data].forEach(v => {
          total = total + ((v['total'] * v[type]) / 100);
        });
      }
      if (type == 'total_with_gst') {
        this[data].forEach(v => {
          total = total + v['total'] + ((v['total'] * v['gst_percent']) / 100);
        });
      }
    }
    return total;
  }

  getGST(total, gstpercent) {
    var gst = 0;
    if (gstpercent > 0) {
      gst = ((total * gstpercent) / 100);
    }
    return gst;
  }

  selectedOption(val, param, array, idparam, matchval) {
    this[array].forEach(v => {
      if (v[matchval] == val) {
        this[param] = v[idparam];
        return false;
      }
    });
  }

  getStockPDF() {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.loadingPDF = true;
    this.httpClient.get(Ecommerce_Config["api_url"] + 'stock_position_pdf', { headers: header }).subscribe(
      (res) => {
        this.loadingPDF = false;
        if (res["code"] == 200) {
          window.open(Ecommerce_Config['img_url'] + res['data'], '_blank');
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loadingPDF = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }

  getStockExcel() {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.loadingExcel = true;
    this.httpClient.get(Ecommerce_Config["api_url"] + 'stock_position_excel', { headers: header }).subscribe(
      (res) => {
        this.loadingExcel = false;
        if (res["code"] == 200) {
          window.open(Ecommerce_Config['img_url'] + res['data'], '_blank');
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loadingExcel = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }

}