<app-toolbar></app-toolbar>
<div *ngIf="route.children.length === 0">
    <mat-progress-bar *ngIf="loading == true" mode="indeterminate" color="warn"></mat-progress-bar>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" class="toolbar">
        <div class="action-lbl">
            Actions:
        </div>
        <button mat-raised-button color="accent" routerLink="/user/add">
            Add User
        </button>
        <button mat-raised-button (click)="getUser(pageind)">
            Refresh
        </button>
        <mat-form-field [floatLabel]="'never'">
            <mat-label>Sort by</mat-label>
            <mat-select [(ngModel)]="sort_option" (selectionChange)="getUser(pageind)" [disableOptionCentering]="true">
                <mat-option value="created_at-desc">
                    Created DESC
                </mat-option>
                <mat-option value="created_at-asc">
                    Created ASC
                </mat-option>
                <mat-option value="name-desc">
                    Name DESC
                </mat-option>
                <mat-option value="name-asc">
                    Name ASC
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field [floatLabel]="'never'">
            <input type="text" matInput placeholder="Search" [(ngModel)]="search_qry" (input)="getUser(0)" />
        </mat-form-field>
        <mat-paginator [length]="page_length" [pageSize]="20" [pageIndex]="pageind" (page)="getUserData($event)"
            [hidePageSize]="true">
        </mat-paginator>
    </div>
    <div class="container">
        <mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChanged($event);">
            <mat-tab label="Supplier">
                <mat-list>
                    <mat-list-item *ngFor="let item of items; let lastItem = last">
                        <div mat-line> {{item.name}} - {{item.mobile}}</div>
                        <div mat-line> Supplier since {{item.created_at|date:'medium'}} </div>
                        <mat-action-list>
                            <button mat-button routerLink="/user/update/{{item.code}}">
                                Edit
                            </button>
                        </mat-action-list>
                        <mat-action-list>
                            <mat-slide-toggle [(ngModel)]="item.status" (change)="changeStatus($event, item.code)">
                            </mat-slide-toggle>
                        </mat-action-list>
                        <mat-divider *ngIf="!lastItem"></mat-divider>
                    </mat-list-item>
                </mat-list>
            </mat-tab>
            <mat-tab label="Customer">
                <mat-list>
                    <mat-list-item *ngFor="let item of items; let lastItem = last">
                        <div mat-line> {{item.name}} - {{item.mobile}}</div>
                        <div mat-line> Vendor since {{item.created_at|date:'medium'}} </div>
                        <mat-action-list>
                            <button mat-button routerLink="/user/update/{{item.code}}">
                                Edit
                            </button>
                        </mat-action-list>
                        <mat-action-list>
                            <mat-slide-toggle [(ngModel)]="item.status" (change)="changeStatus($event, item.code)">
                            </mat-slide-toggle>
                        </mat-action-list>
                        <mat-divider *ngIf="!lastItem"></mat-divider>
                    </mat-list-item>
                </mat-list>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
<router-outlet></router-outlet>