import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Ecommerce_Config } from '../Ecommerce_Config';
import { FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

@Component({
  selector: 'confirm-dialog',
  templateUrl: 'confirm.html',
})
export class ConfirmDialog {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
}

@Component({
  selector: 'user-quick-dialog',
  templateUrl: 'user_quick_add.html',
})
export class UserQuickAddDialog {
  userForm: FormGroup;
  loading = false;

  ngOnInit(): void {
    this.userForm = new FormGroup({
      'name': new FormControl('', [Validators.required]),
      'gst': new FormControl('', [Validators.required]),
      'email': new FormControl('', [Validators.required]),
      'mobile': new FormControl('', [Validators.required]),
      'role': new FormControl((this.data.type).toLowerCase(), [Validators.required]),
      'isactive': new FormControl(true, [Validators.required])
    });
  }

  constructor(
    private httpClient: HttpClient,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<UserQuickAddDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  addUser() {
    if (this.userForm.invalid) {
      return;
    }
    this.loading = true;
    var formData = { name: this.userForm.value.name, company_name: '-', gst: this.userForm.value.gst, address: '-', email: this.userForm.value.email, mobile: this.userForm.value.mobile, role: this.userForm.value.role, isactive: this.userForm.value.isactive };
    if (this.userForm.value.isactive == false) {
      formData.isactive = '0';
    }
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.httpClient.post(Ecommerce_Config["api_url"] + 'user', formData, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.dialogRef.close(true);
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }
}

@Component({
  selector: 'alert-dialog',
  templateUrl: 'alert.html',
})
export class AlertDialog {
  constructor(
    public dialogRef: MatDialogRef<AlertDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
}

@Component({
  selector: 'imgpreview-dialog',
  templateUrl: 'imgpreview.html',
})
export class ImagePreviewDialog {
  imgpath: string = Ecommerce_Config.img_url;
  constructor(
    public dialogRef: MatDialogRef<ImagePreviewDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
}

@Component({
  selector: 'billpreview-dialog',
  templateUrl: 'billpreview.html',
})
export class BillPreviewDialog {
  imgpath: string = Ecommerce_Config.img_url;
  constructor(
    public dialogRef: MatDialogRef<BillPreviewDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
}

@Component({
  selector: 'inventorydetail-dialog',
  templateUrl: 'inventorydetail.html',
})
export class InventoryDetail {
  product: any = null;
  loading = true;
  constructor(
    public dialogRef: MatDialogRef<InventoryDetail>, private _snackBar: MatSnackBar, private httpClient: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.httpClient.get(Ecommerce_Config["api_url"] + 'inventorylines/' + this.data.code, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.product = res["data"];
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }

}