<div class="container notoolbar">
    <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="20px" fxLayout.xs="column">
        <div fxFlex="50" fxLayout="column" fxLayoutAlign="start stretch">
            <div style="max-height: 45vh; overflow-x: auto;" fxLayout="column">
                <table mat-table [dataSource]="allproductvariants" class="mat-elevation-z8"
                    style="border: 1px solid rgba(0, 0, 0, 0.12)">
                    <ng-container matColumnDef="product">
                        <th mat-header-cell *matHeaderCellDef style="border-right: 1px solid rgba(0, 0, 0, 0.12);">
                            Product </th>
                        <td mat-cell *matCellDef="let element" style="border-right: 1px solid rgba(0, 0, 0, 0.12);">
                            {{element.name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="unit">
                        <th mat-header-cell *matHeaderCellDef
                            style="border-right: 1px solid rgba(0, 0, 0, 0.12); padding-right: 10px; padding-left: 10px;">
                            Unit </th>
                        <td mat-cell *matCellDef="let element"
                            style="border-right: 1px solid rgba(0, 0, 0, 0.12); padding-right: 10px; padding-left: 10px;">
                            {{element.sign}} </td>
                    </ng-container>

                    <ng-container matColumnDef="price">
                        <th mat-header-cell *matHeaderCellDef style="padding-left: 10px;"> Price </th>
                        <td mat-cell *matCellDef="let element" style="padding-left: 10px;">
                            ₹{{element.price| number: '1.2-2'}}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <!--<a href="https://api.whatsapp.com/send?phone=9884794962&text=Hi&media=">Send Message</a>-->

            <form [formGroup]="saleProductForm" autocomplete="off" fxLayout="column" fxLayoutAlign="stretch start"
                fxLayoutGap="20px" style="width: 100%;">
                <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px" style="width: 100%;">
                    <mat-form-field appearance="legacy" style="width: 50%;">
                        <mat-label>Product</mat-label>
                        <mat-select #product_dropdown [disableOptionCentering]="true" formControlName="product"
                            (selectionChange)="selectedOptionProduct(saleProductForm.value.product, 'productvariants')">
                            <mat-option [value]="row.mv_product_variant_id" *ngFor="let row of productvariants">
                                {{row.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="saleProductForm.get('product').hasError('required')">
                            Product is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="legacy" style="width: 50%;">
                        <mat-label>Quantity {{unitin}}</mat-label>
                        <input #quantity matInput type="number" step="0.01" placeholder=""
                            formControlName="product_qty" />
                        <mat-error *ngIf="saleProductForm.get('product_qty').hasError('required')">
                            Quantity is required
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px" style="width: 100%;">
                    <button [disabled]="!saleProductForm.valid" mat-raised-button color="primary" type="button"
                        (click)="addProductToInventory()">
                        Add(F9)
                    </button>
                    <button [disabled]="!saleProductForm.valid" mat-raised-button color="primary" type="button"
                        (click)="addProductToInventoryw()">
                        Add W(F11)
                    </button>
                </div>
            </form>
        </div>
        <div fxFlex="50" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px" fxLayout.xs="column">
            <div style="width: 90%;" fxLayout="column" fxLayoutAlign="stretch end"
                *ngIf="inventoryproducts.items.length > 0">
                <div fxLayout="row" *ngFor="let item of inventoryproducts.items; let i=index;  let lastItem = last"
                    style="width: 100%;" fxLayoutAlign="start center" class="mat-subheading-2">
                    <div style="width: 15%; text-align: center;">
                        <button mat-button (click)="removeProduct(i)">
                            Remove
                        </button>
                    </div>
                    <div style="width: 40%;">
                        {{item.product_name}}
                    </div>
                    <div style="width: 25%;">
                        {{item.product_qty}} {{item.unit_name}} <span *ngIf="item.product_price_type == 'retail'">X
                            ₹{{item.product_price| number:'1.2-2'}}</span>
                    </div>
                    <div style="width: 20%; text-align: right;">
                        ₹{{item.product_total| number:'1.2-2'}}
                    </div>
                    <mat-divider *ngIf="!lastItem"></mat-divider>
                </div>
                <mat-divider style="width: 100%;"></mat-divider>
                <div class="mat-display-1 no-margin" style="text-align: right; display: flex; align-items: center;">
                    <mat-slide-toggle [(ngModel)]="enable_gst"></mat-slide-toggle>
                    <input matInput #gst_percent_ele type="number" *ngIf="enable_gst == true" step="1" placeholder=""
                        [(ngModel)]="gst_percent" style="width: 80px;" />
                    <span *ngIf="enable_gst == true">%</span>&nbsp;GST:&nbsp;
                    <span>₹{{getGSTTotal()|number: '1.2-2'}}</span>
                </div>
                <div class="mat-display-1 no-margin" style="text-align: right;">Grand Total:
                    ₹{{(getGrandTotal() + getGSTTotal())|number: '1.2-2'}}</div>
                <mat-form-field appearance="legacy" class="mat-subheading-2">
                    <mat-label>Amt. Paid(F1) ₹</mat-label>
                    <input matInput #paid_amt_ele type="number" step="0.01" placeholder="" [(ngModel)]="paid_amt" />
                </mat-form-field>
                <mat-divider style="width: 100%;"></mat-divider>
                <div class="mat-headline no-margin">
                    Amt. Return: ₹<span *ngIf="paid_amt > 0">{{paid_amt - (getGrandTotal() + getGSTTotal())|number:
                        '1.2-2'}}</span><span *ngIf="paid_amt == 0">{{0|number: '1.2-2'}}</span>
                </div>
                <mat-form-field>
                    <mat-label>Customer</mat-label>
                    <input matInput placeholder="" aria-label="User" [matAutocomplete]="auto"
                        [formControl]="userCtrl" />
                    <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="selectedOption($event.option.value, 'user', 'users', 'mv_user_id', 'name')">
                        <mat-option *ngIf="loading_options">
                            <mat-progress-spinner [mode]="'indeterminate'" [strokeWidth]="3" [diameter]="25">
                            </mat-progress-spinner>
                        </mat-option>
                        <mat-option *ngFor="let row of users" [value]="row.name">
                            {{row.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="20px">
                    <button mat-raised-button color="accent" (click)="addUser()">Add Customer</button>
                    <button mat-raised-button color="primary" (click)="addInventory()" [disabled]="loading">
                        <span *ngIf="loading == false" class="mat-display-1">Save(F2)</span>
                        <mat-progress-spinner *ngIf="loading == true" color="accent" mode="indeterminate"
                            strokeWidth="4" diameter="40" class="auto-margin"></mat-progress-spinner>
                    </button>
                </div>
            </div>
            <div *ngIf="receiptPDF != '' && this.inventoryproducts && this.inventoryproducts.needprint == true">
                <iframe style="width: 100%; height: 600px;" [src]="receiptPDF"></iframe>
            </div>
        </div>
    </div>
</div>