<div class="container notoolbar">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" fxLayout.xs="column">
        <button mat-raised-button routerLink="/product">
            Back to Product
        </button>
    </div>
    <div fxLayout="row" fxLayoutAlign="center stretch" fxLayoutGap="20px" fxLayout.xs="column">
        <form [formGroup]="productForm" autocomplete="off" (ngSubmit)="addProduct()" fxLayout="row"
            fxLayoutAlign="start start" fxLayoutGap="10px" style="width: 70%;">
            <div fxLayout="column" fxLayoutAlign="stretch stretch" fxLayoutGap="10px" fxFlex="30">
                <mat-form-field appearance="legacy">
                    <mat-label>Product Name</mat-label>
                    <input matInput type="text" placeholder="" formControlName="product_name" />
                    <mat-error *ngIf="productForm.get('product_name').hasError('required')">
                        Product name is required
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Category</mat-label>
                    <input matInput placeholder="" aria-label="State" [matAutocomplete]="auto"
                        [formControl]="categoryCtrl" />
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedOption($event.option.value, 'product_category', 'categories', 'mv_category_id', 'name')">
                        <mat-option *ngIf="loading_options">
                            <mat-progress-spinner [mode]="'indeterminate'" [strokeWidth]="3" [diameter]="25">
                            </mat-progress-spinner>
                        </mat-option>
                        <mat-option *ngFor="let row of categories" [value]="row.name">
                            {{row.name}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-hint *ngIf="productForm.get('product_category').hasError('required')">
                        <span class="mat-error">Product category is required</span>
                    </mat-hint>
                </mat-form-field>
                <mat-checkbox formControlName="isactive"> Active</mat-checkbox>
                <div class="image" *ngIf="img_path != ''">
                    <button mat-button color="primary" aria-label="delete" (click)="removeImage()">
                        Delete
                    </button>
                    <img [src]="img_path" alt="image" />
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
                <ejs-richtexteditor #toolsRTE id="alltoolRTE" [toolbarSettings]="tools" formControlName="product_description"></ejs-richtexteditor>
                <mat-form-field appearance="legacy">
                    <ngx-mat-file-input (change)="fileProgress($event, 'img_path')" placeholder="Product image" formControlName="product_image" [accept]="'.png, .jpg'"></ngx-mat-file-input>
                    <mat-error *ngIf="productForm.get('product_image').hasError('maxContentSize')">
                        Maximum file size is 1MB
                      </mat-error>
                </mat-form-field>
                <button [disabled]="!productForm.valid || loading" mat-raised-button color="primary" type="submit">
                    <span *ngIf="loading == false">Add Product</span>
                    <mat-progress-spinner *ngIf="loading == true" color="accent" mode="indeterminate" strokeWidth="4"
                        diameter="40" class="auto-margin"></mat-progress-spinner>
                </button>
            </div>
        </form>
    </div>
</div>