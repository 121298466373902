import { Component, OnInit } from '@angular/core';
import { Ecommerce_Config } from '../Ecommerce_Config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  displayedColumns: string[] = ['product', 'stock_left', 'sale_today', 'overall_sale'];
  loading: boolean = false;
  dataSource: any = [{
    product: 'Milk',
    stock_left: '10L',
    sale_today: '390L',
    overall_sale: '3900L',
  }];
  chart: any = {
    title: 'Best Sale', type: 'PieChart', options: { is3D: true, height: 400 }, data: []
  };
  columnchart: any = {
    title: 'Sales', type: 'ColumnChart', options: { is3D: true, height: 400 }, column: ['Month', 'Sale'], data: []
  };
  constructor(private httpClient: HttpClient, private _snackBar: MatSnackBar) {
    Ecommerce_Config["page_title"] = 'Dashboard';
  }

  ngOnInit(): void {
    this.getDashboard();
  }

  getDashboard() {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.loading = true;
    this.httpClient.get(Ecommerce_Config["api_url"] + 'get_dashboard', { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.chart.data = [];
          this.columnchart.data = [];
          this.dataSource = res["data"]["stock"];
          if (res["data"]["cat_sale"].length > 0) {
            res["data"]["cat_sale"].forEach(element => {
              (this.chart.data).push([element.name, element.sale]);
            });
          }
          if (res["data"]["month_sale"].length > 0) {
            res["data"]["month_sale"].forEach(element => {
              (this.columnchart.data).push([element.month, element.amt]);
            });
          }
        } else {
          this.dataSource = [];
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }

}
