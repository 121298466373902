<div class="container notoolbar">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" fxLayout.xs="column">
        <button mat-raised-button routerLink="/productvariant">
            Back to Product Variant
        </button>
    </div>
    <div fxLayout="row" fxLayoutAlign="center stretch" fxLayoutGap="20px" fxLayout.xs="column">
        <div style="width: 60%;" fxLayout="row" fxLayoutAlign="stretch stretch" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="stretch stretch" fxLayoutGap="20px" fxFlex="50">
                <div *ngIf="items.length > 0">
                    <div class="image" *ngFor="let item of items;">
                        <button mat-button color="primary" aria-label="delete"
                            (click)="deleteImage(item.mv_product_gallery_id)">
                            Delete
                        </button>
                        <img [src]="image_url + item.source_value" alt="image" />
                    </div>
                </div>
            </div>
            <form [formGroup]="productvariantGalleryForm" autocomplete="off" (ngSubmit)="addProductvariantGallery()"
                fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px" style="width: 100%;">
                <mat-form-field appearance="legacy">
                    <ngx-mat-file-input (change)="fileProgress($event, 'img_paths')" placeholder="Variant images"
                        formControlName="gallery" [accept]="'.png, .jpg'" multiple></ngx-mat-file-input>
                    <mat-error *ngIf="productvariantGalleryForm.get('gallery').hasError('maxContentSize')">
                        Maximum file size is 1MB
                    </mat-error>
                </mat-form-field>
                <div *ngIf="img_paths.length > 0">
                    <div class="image" *ngFor="let item of img_paths;let indexOfelement=index;">
                        <button mat-button color="primary" aria-label="delete" (click)="removeImage(indexOfelement)">
                            Delete
                        </button>
                        <img [src]="item" alt="image" />
                    </div>
                </div>
                <button [disabled]="!productvariantGalleryForm.valid || loading" mat-raised-button color="primary"
                    type="submit">
                    <span *ngIf="loading == false">Add Gallery</span>
                    <mat-progress-spinner *ngIf="loading == true" color="accent" mode="indeterminate" strokeWidth="4"
                        diameter="40" class="auto-margin"></mat-progress-spinner>
                </button>
            </form>
        </div>
    </div>
</div>