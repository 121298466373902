import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Ecommerce_Config } from '../Ecommerce_Config';
import * as moment from 'moment';

@Component({
  selector: 'app-denomination',
  templateUrl: './denomination.component.html',
  styleUrls: ['./denomination.component.css']
})
export class DenominationComponent implements OnInit {
  @ViewChild('vendordetail', { static: false }) vendordetail: ElementRef;
  loading: boolean = false;
  status: boolean = false;
  from: Date = new Date();
  to: Date = new Date();
  user: Number = 0;
  loading_options = false;
  loadingExcel = false;
  loadingPDF = false;
  selectedTabIndex: number = 0;
  userCtrl: FormControl = new FormControl();
  displayedColumns: string[] = ['user', 'paid_amt', 'outstanding_amt', 'last_paid_amt', 'last_paid_on'];
  dataSource: any = [];
  displayedColumnsDetail: string[] = ['user', 'date', 'order', 'paid_amount'];
  dataSourceDetail: any = [];
  users: any = [];
  constructor(private _snackBar: MatSnackBar, private httpClient: HttpClient, public dialog: MatDialog, private cdRef: ChangeDetectorRef) {
    Ecommerce_Config["page_title"] = 'Payment Report';
  }

  ngOnInit(): void {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.userCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.users = [];
          this.loading_options = true;
        }),
        switchMap(srch => this.httpClient.get<any>(Ecommerce_Config["api_url"] + 'vendorncustomer_autocomplete/' + srch, { headers: header })
          .pipe(
            finalize(() => {
              this.loading_options = false
            }),
          )
        )
      )
      .subscribe(res => {
        if (res["code"] == 200) {
          this.users = res["data"];
        } else {
          this.users = [];
        }
      });
    this.getPayments();
  }

  onTabChanged(ev) {
    if (ev.index == 0) {
      this.getPayments();
    } else if (ev.index == 1) {
      setTimeout(() => {
        this.userCtrl.setValue('');
        this.user = 0;
        this.vendordetail.nativeElement.focus();
        this.cdRef.detectChanges();
      }, 100);
    }
  }

  getPayments() {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.loading = true;
    this.httpClient.get(Ecommerce_Config["api_url"] + 'payment_summary', { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.dataSource = res["data"];
        } else {
          this.dataSource = [];
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.dataSource = [];
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }

  getPaymentsPDF() {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.loadingPDF = true;
    this.httpClient.get(Ecommerce_Config["api_url"] + 'payment_summary_pdf', { headers: header }).subscribe(
      (res) => {
        this.loadingPDF = false;
        if (res["code"] == 200) {
          window.open(Ecommerce_Config['img_url'] + res['data'], '_blank');
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loadingPDF = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }

  getPaymentsExcel() {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.loadingExcel = true;
    this.httpClient.get(Ecommerce_Config["api_url"] + 'payment_summary_excel', { headers: header }).subscribe(
      (res) => {
        this.loadingExcel = false;
        if (res["code"] == 200) {
          window.open(Ecommerce_Config['img_url'] + res['data'], '_blank');
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loadingExcel = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }

  getPaymentDetail() {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.loading = true;
    this.httpClient.get(Ecommerce_Config["api_url"] + 'payment_detail/' + moment(this.from).format('YYYY-MM-DD') + '/' + moment(this.to).format('YYYY-MM-DD') + '/' + this.user, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.dataSourceDetail = res["data"];
        } else {
          this.dataSourceDetail = [];
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.dataSourceDetail = [];
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }

  getPaymentDetailExcel() {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.loadingExcel = true;
    this.httpClient.get(Ecommerce_Config["api_url"] + 'payment_detail_excel/' + moment(this.from).format('YYYY-MM-DD') + '/' + moment(this.to).format('YYYY-MM-DD') + '/' + this.user, { headers: header }).subscribe(
      (res) => {
        this.loadingExcel = false;
        if (res["code"] == 200) {
          window.open(Ecommerce_Config['img_url'] + res['data'], '_blank');
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loadingExcel = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }

  getPaymentDetailPDF() {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.loadingPDF = true;
    this.httpClient.get(Ecommerce_Config["api_url"] + 'payment_detail_pdf/' + moment(this.from).format('YYYY-MM-DD') + '/' + moment(this.to).format('YYYY-MM-DD') + '/' + this.user, { headers: header }).subscribe(
      (res) => {
        this.loadingPDF = false;
        if (res["code"] == 200) {
          window.open(Ecommerce_Config['img_url'] + res['data'], '_blank');
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loadingPDF = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }

  selectedOption(val, param, array, idparam, matchval) {
    this[array].forEach(v => {
      if (v[matchval] == val) {
        this[param] = v[idparam];
        return false;
      }
    });
  }

  getTotal(data, type) {
    var total = 0;
    this[data].forEach(v => {
      total = total + v[type];
    });
    return total;
  }

}
