<div class="container notoolbar">
    <!--<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" fxLayout.xs="column">
        <button mat-raised-button routerLink="/inventory">
            Back to Purchase
        </button>
    </div>-->
    <div fxLayout="row" fxLayoutAlign="center stretch" fxLayoutGap="20px" fxLayout.xs="column">
        <form [formGroup]="purchaseProductForm" autocomplete="off" (ngSubmit)="addProductToInventory()" fxLayout="row"
            fxLayoutAlign="start start" fxLayoutGap="10px" style="width: 70%;">
            <mat-form-field>
                <mat-label>Product</mat-label>
                <input #product matInput placeholder="" aria-label="State" [matAutocomplete]="auto"
                    [formControl]="productvariantCtrl" />
                <mat-autocomplete #auto="matAutocomplete"
                    (optionSelected)="selectedOption($event.option.value, 'product', 'productvariants', 'mv_product_variant_id', 'name')">
                    <mat-option *ngIf="loading_options">
                        <mat-progress-spinner [mode]="'indeterminate'" [strokeWidth]="3" [diameter]="25">
                        </mat-progress-spinner>
                    </mat-option>
                    <mat-option *ngFor="let row of productvariants" [value]="row.name">
                        {{row.name}}
                    </mat-option>
                </mat-autocomplete>
                <mat-hint *ngIf="purchaseProductForm.get('product').hasError('required')">
                    <span class="mat-error">Product is required</span>
                </mat-hint>
            </mat-form-field>
            <mat-form-field appearance="legacy">
                <mat-label>Quantity {{unitin}}</mat-label>
                <input #quantity matInput type="number" step="0.01" placeholder="" formControlName="product_qty" />
                <mat-error *ngIf="purchaseProductForm.get('product_qty').hasError('required')">
                    Quantity is required
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="legacy">
                <mat-label>Price{{unitslash}}</mat-label>
                <input #price matInput type="number" step="0.01" placeholder="" formControlName="product_price" />
                <mat-error *ngIf="purchaseProductForm.get('product_price').hasError('required')">
                    Price is required
                </mat-error>
            </mat-form-field>
            <div class="mat-line">
                ₹{{purchaseProductForm.value.product_price * purchaseProductForm.value.product_qty | number:'1.2-2'}}
            </div>
            <button [disabled]="!purchaseProductForm.valid" mat-raised-button color="primary" type="submit">
                Add
            </button>
        </form>
    </div>
    <br />
    <br />
    <div fxLayout="column" fxLayoutAlign="end stretch" fxLayoutGap="20px" fxLayout.xs="column"
        *ngIf="purchaseproducts.items.length > 0">
        <div style="width: 70%;" fxLayout="column" fxLayoutAlign="stretch end">
            <div fxLayout="column" fxLayoutAlign="start center" style="width: 100%;">
                <div fxLayout="row" *ngFor="let item of purchaseproducts.items; let i=index;  let lastItem = last"
                    style="width: 100%;" fxLayoutAlign="start center">
                    <div style="width: 10%; text-align: center;">
                        <button mat-button (click)="removeProduct(i)">
                            Remove
                        </button>
                    </div>
                    <div style="width: 40%;">
                        {{item.product_name}}
                    </div>
                    <div style="width: 30%;">
                        {{item.product_qty}} {{item.unit_name}} X
                        ₹{{item.product_price| number:'1.2-2'}}/{{item.unit_name}}
                    </div>
                    <div style="width: 20%; text-align: right;">
                        ₹{{item.product_total| number:'1.2-2'}}
                    </div>
                    <mat-divider *ngIf="!lastItem"></mat-divider>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="mat-line" style="text-align: right;"><mat-slide-toggle
                    [(ngModel)]="enable_gst"></mat-slide-toggle>
                <input matInput #gst_percent_ele type="number" *ngIf="enable_gst == true" step="1" placeholder=""
                    [(ngModel)]="gst_percent" style="width: 80px;" />
                <span *ngIf="enable_gst == true">%</span>&nbsp;GST: ₹{{getGSTTotal()|number: '1.2-2'}}
            </div>
            <div class="mat-line" style="text-align: right;">Grand Total: ₹{{(getGrandTotal() + getGSTTotal())|number:
                '1.2-2'}}</div>
            <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="20px">
                <mat-form-field>
                    <mat-label>Supplier</mat-label>
                    <input matInput placeholder="" aria-label="State" [matAutocomplete]="auto2"
                        [formControl]="supplierCtrl" />
                    <mat-autocomplete #auto2="matAutocomplete"
                        (optionSelected)="selectedOption($event.option.value, 'supplier', 'suppliers', 'mv_user_id', 'name')">
                        <mat-option *ngIf="loading_options">
                            <mat-progress-spinner [mode]="'indeterminate'" [strokeWidth]="3" [diameter]="25">
                            </mat-progress-spinner>
                        </mat-option>
                        <mat-option *ngFor="let row of suppliers" [value]="row.name">
                            {{row.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field appearance="legacy">
                    <mat-label>Amt. Paid</mat-label>
                    <input matInput type="number" step="0.01" placeholder="" [(ngModel)]="purchaseproducts.paidamt" />
                </mat-form-field>
                <mat-form-field appearance="legacy">
                    <mat-label>Remark</mat-label>
                    <input matInput type="text" placeholder="" [(ngModel)]="purchaseproducts.remark" />
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="20px">
                <button mat-raised-button color="accent" (click)="addUser()">Add Supplier</button>
                <button mat-raised-button color="primary" (click)="checkPaidAmt()" [disabled]="loading">
                    <span *ngIf="loading == false">Add Purchase</span>
                    <mat-progress-spinner *ngIf="loading == true" color="accent" mode="indeterminate" strokeWidth="4"
                        diameter="40" class="auto-margin"></mat-progress-spinner>
                </button>
            </div>
        </div>
    </div>
</div>