<h1 mat-dialog-title>#{{data.order_id}}</h1>
<div mat-dialog-content>
  <mat-progress-bar *ngIf="loading == true" mode="indeterminate" color="warn"></mat-progress-bar>
  <mat-list>
    <mat-list-item *ngFor="let item of product; let lastItem = last">
      <div mat-line> {{item.name}} </div>
      <div mat-line> {{item.quantity}} {{item.unit}} X ₹{{item.price|number:'1.2-2'}}/{{item.unit}}</div>
      <mat-action-list>
        ₹{{item.total|number:'1.2-2'}}
      </mat-action-list>
      <mat-divider *ngIf="!lastItem"></mat-divider>
    </mat-list-item>
  </mat-list>
</div>
<div mat-dialog-actionss align="end">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Close</button>
</div>