<div class="container notoolbar">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" fxLayout.xs="column">
        <button mat-raised-button routerLink="/productvariant">
            Back to Product Variant
        </button>
    </div>
    <div fxLayout="row" fxLayoutAlign="center stretch" fxLayoutGap="20px" fxLayout.xs="column">
        <form [formGroup]="productvariantPriceForm" autocomplete="off" (ngSubmit)="updateProductvariantPrice()"
            fxLayout="column" fxLayoutAlign="stretch start" fxLayoutGap="10px" style="width: 30%;">
            <h3>{{variantname}}</h3>
            <mat-form-field appearance="legacy">
                <mat-label>Retail Price{{perunit}}</mat-label>
                <input matInput type="text" placeholder="" formControlName="retail_price" />
                <mat-error *ngIf="productvariantPriceForm.get('retail_price').hasError('required')">
                    Retail price is required
                </mat-error>
            </mat-form-field>
            <mat-checkbox formControlName="has_discount"> Discount</mat-checkbox>
            <mat-form-field appearance="legacy" *ngIf="productvariantPriceForm.value.has_discount == true">
                <mat-label>Retail Discount Price{{perunit}}</mat-label>
                <input matInput type="text" placeholder="" formControlName="discount_price" />
                <mat-error *ngIf="productvariantPriceForm.get('discount_price').hasError('required')">
                    Retail discount price is required
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="legacy">
                <mat-label>Whole Sale Price{{perunit}}</mat-label>
                <input matInput type="text" placeholder="" formControlName="wholesale_price" />
                <mat-error *ngIf="productvariantPriceForm.get('wholesale_price').hasError('required')">
                    Wholesale price is required
                </mat-error>
            </mat-form-field>
            <button [disabled]="!productvariantPriceForm.valid || loading" mat-raised-button color="primary"
                type="submit">
                <span *ngIf="loading == false">Change Price</span>
                <mat-progress-spinner *ngIf="loading == true" color="accent" mode="indeterminate" strokeWidth="4"
                    diameter="40" class="auto-margin"></mat-progress-spinner>
            </button>
        </form>
    </div>
</div>