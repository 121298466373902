import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Ecommerce_Config } from '../Ecommerce_Config';
import { InventoryDetail } from '../dialog/dialog.component';
import * as moment from 'moment';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { ConfirmDialog, UserQuickAddDialog } from '../dialog/dialog.component';


@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.css']
})
export class PurchaseComponent implements OnInit {
  sort_option: string = 'created_at-desc';
  search_qry: string = '';
  loading: boolean = false;
  date: Date = new Date();
  items: any = [];
  constructor(public route: ActivatedRoute, private _snackBar: MatSnackBar, private httpClient: HttpClient, public dialog: MatDialog) {
    if (this.route.snapshot.params.category) {
    }
    Ecommerce_Config["page_title"] = 'Purchase';
  }
  getPurchase() {
    this.loading = true;
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    var query = null;
    if (this.search_qry != '') {
      query = this.search_qry;
    }
    this.httpClient.get(Ecommerce_Config["api_url"] + 'purchase/' + query + '/' + this.sort_option + '/' + moment(this.date).format('YYYY-MM-DD'), { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.items = res["data"];
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }
  ngOnInit(): void {
    this.getPurchase();
  }
  showProducts(row) {
    const dialogRef = this.dialog.open(InventoryDetail, {
      minWidth: "40%",
      maxWidth: "40%",
      disableClose: true,
      data: row
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }
  getSum(param, data) {
    var total = 0;
    data.forEach(v => {
      total = total + v[param];
    });
    return total;
  }
}

@Component({
  selector: 'app-add-purchase',
  templateUrl: './add.purchase.component.html',
  styleUrls: ['./purchase.component.css']
})
export class AddPurchaseComponent implements OnInit {
  @ViewChild('product', { static: false }) product: ElementRef;
  @ViewChild('quantity', { static: false }) quantity: ElementRef;
  @ViewChild('price', { static: false }) price: ElementRef;
  @ViewChild('gst_percent_ele', { static: false }) gst_percent_ele: ElementRef;
  purchaseProductForm: FormGroup;
  productvariantCtrl: FormControl = new FormControl();
  supplierCtrl: FormControl = new FormControl();
  productvariants: any = [];
  suppliers: any = [];
  purchaseproducts: any = { paidamt: null, remark: '', items: [] };
  loading_options: boolean = false;
  loading: boolean = false;
  unitin: string = '';
  unitslash: string = '';
  gst_percent: number = 18;
  enable_gst: boolean = false;
  constructor(public route: ActivatedRoute, private _snackBar: MatSnackBar, private httpClient: HttpClient, private router: Router, public dialog: MatDialog, private cdRef: ChangeDetectorRef) {
    this.purchaseProductForm = new FormGroup({
      'product_name': new FormControl('', [Validators.required]),
      'product': new FormControl('', [Validators.required]),
      'product_qty': new FormControl(null, [Validators.required]),
      'product_price': new FormControl(null, Validators.required),
      'product_unit': new FormControl(null, Validators.required),
      'unit_name': new FormControl(null, Validators.required),
      'supplier': new FormControl(0)
    });
  }
  addProductToInventory() {
    (this.purchaseproducts.items).push({ product: this.purchaseProductForm.value.product, product_name: this.purchaseProductForm.value.product_name, unit_name: this.purchaseProductForm.value.unit_name, product_qty: this.purchaseProductForm.value.product_qty, product_price: this.purchaseProductForm.value.product_price, product_unit: this.purchaseProductForm.value.product_unit, product_total: (this.purchaseProductForm.value.product_price * this.purchaseProductForm.value.product_qty) });
    this.purchaseProductForm.reset();
    this.productvariantCtrl.setValue('');
    this.product.nativeElement.focus();
  }
  getGrandTotal() {
    var total = 0;
    this.purchaseproducts.items.forEach(v => {
      total = total + v.product_total;
    });
    return total;
  }
  getGSTTotal() {
    var res = 0;
    if (this.enable_gst == true) {
      var total = this.getGrandTotal();
      res = total * (this.gst_percent / 100);
    }
    return res;
  }
  checkPaidAmt() {
    if (this.purchaseproducts.paidamt == '' || this.purchaseproducts.paidamt == null) {
      const dialogRef = this.dialog.open(ConfirmDialog, {
        minWidth: "30%",
        maxWidth: "30%",
        disableClose: true,
        data: { title: 'Confirmation', message: 'Are you sure to continue without paid amount?', cancel: 'No', accept: 'Yes' }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result == true) {
          this.purchaseproducts.paidamt = 0;
          this.addInventory();
        }
      });
    } else {
      this.addInventory();
    }
  }
  addInventory() {
    this.loading = true;
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.purchaseproducts.supplier = this.purchaseProductForm.value.supplier;
    this.purchaseproducts.gst_percent = (this.enable_gst == true ? this.gst_percent : 0);
    this.purchaseproducts.gst_amount = this.getGSTTotal();
    this.purchaseproducts.grand_total = (this.getGrandTotal() + this.getGSTTotal());
    this.purchaseproducts.inventory_type = 'purchase';
    this.httpClient.post(Ecommerce_Config["api_url"] + 'inventory', this.purchaseproducts, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.purchaseproducts = { paidamt: null, remark: '', items: [] };
          this.product.nativeElement.focus();
          this.router.navigateByUrl('/purchase');
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }
  ngOnInit(): void {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.productvariantCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.productvariants = [];
          this.loading_options = true;
        }),
        switchMap(srch => this.httpClient.get<any>(Ecommerce_Config["api_url"] + 'productvariant_autocomplete/' + srch, { headers: header })
          .pipe(
            finalize(() => {
              this.loading_options = false
            }),
          )
        )
      )
      .subscribe(res => {
        if (res["code"] == 200) {
          this.productvariants = res["data"];
        } else {
          this.productvariants = [];
        }
      });
    this.supplierCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.suppliers = [];
          this.loading_options = true;
        }),
        switchMap(srch => this.httpClient.get<any>(Ecommerce_Config["api_url"] + 'supplier_autocomplete/' + srch, { headers: header })
          .pipe(
            finalize(() => {
              this.loading_options = false
            }),
          )
        )
      )
      .subscribe(res => {
        if (res["code"] == 200) {
          this.suppliers = res["data"];
        } else {
          this.suppliers = [];
        }
      });
  }
  ngAfterViewInit(): void {
    this.product.nativeElement.focus();
    this.cdRef.detectChanges();
  }
  selectedOption(val, param, array, idparam, matchval) {
    this[array].forEach(v => {
      if (v[matchval] == val) {
        this.purchaseProductForm.patchValue({
          [param]: v[idparam],
        });
        if (param == 'product') {
          this.unitin = 'in ' + v.unit;
          this.unitslash = '/' + v.unit;
          this.purchaseProductForm.patchValue({
            product_unit: v.mv_unit_id,
            unit_name: v.unit,
            product_name: v.name
          });
          this.quantity.nativeElement.focus();
        }
        return false;
      }
    });
  }
  removeProduct(ind) {
    this.purchaseproducts.items.splice(ind, 1);
  }
  addUser() {
    var row = { type: 'supplier', label: 'Supplier' }
    const dialogRef = this.dialog.open(UserQuickAddDialog, {
      minWidth: "40%",
      maxWidth: "40%",
      disableClose: true,
      data: row
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }
}