<div class="container notoolbar">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" fxLayout.xs="column">
        <button mat-raised-button routerLink="/productvariant">
            Back to Product Variant
        </button>
    </div>
    <div fxLayout="row" fxLayoutAlign="center stretch" fxLayoutGap="20px" fxLayout.xs="column">
        <form [formGroup]="productvariantForm" autocomplete="off" (ngSubmit)="addProductvariant()" fxLayout="row"
            fxLayoutAlign="start start" fxLayoutGap="10px" style="width: 70%;">
            <div fxLayout="column" fxLayoutAlign="stretch stretch" fxLayoutGap="10px" fxFlex="30">
                <mat-form-field appearance="legacy">
                    <mat-label>Variant Name</mat-label>
                    <input matInput type="text" placeholder="" formControlName="variant_name" />
                    <mat-error *ngIf="productvariantForm.get('variant_name').hasError('required')">
                        Variant name is required
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Product</mat-label>
                    <input matInput placeholder="" aria-label="State" [matAutocomplete]="auto"
                        [formControl]="productCtrl" />
                    <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="selectedOption($event.option.value, 'product', 'products', 'mv_product_id', 'name')">
                        <mat-option *ngIf="loading_options">
                            <mat-progress-spinner [mode]="'indeterminate'" [strokeWidth]="3" [diameter]="25">
                            </mat-progress-spinner>
                        </mat-option>
                        <mat-option *ngFor="let row of products" [value]="row.name">
                            {{row.name}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-hint *ngIf="productvariantForm.get('product').hasError('required')">
                        <span class="mat-error">Product is required</span>
                    </mat-hint>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Unit</mat-label>
                    <input matInput placeholder="" aria-label="State" [matAutocomplete]="autounit"
                        [formControl]="unitCtrl" />
                    <mat-autocomplete #autounit="matAutocomplete"
                        (optionSelected)="selectedOption($event.option.value, 'variant_unit', 'units', 'mv_unit_id', 'name')">
                        <mat-option *ngIf="loading_options">
                            <mat-progress-spinner [mode]="'indeterminate'" [strokeWidth]="3" [diameter]="25">
                            </mat-progress-spinner>
                        </mat-option>
                        <mat-option *ngFor="let row of units" [value]="row.name">
                            {{row.name}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-hint *ngIf="productvariantForm.get('variant_unit').hasError('required')">
                        <span class="mat-error">Unit is required</span>
                    </mat-hint>
                </mat-form-field>
                <mat-form-field appearance="legacy">
                    <mat-label>Retail Price{{perunitslash}}</mat-label>
                    <input matInput type="text" placeholder="" formControlName="retail_price" />
                    <mat-error *ngIf="productvariantForm.get('retail_price').hasError('required')">
                        Retail price is required
                    </mat-error>
                </mat-form-field>
                <mat-checkbox formControlName="has_discount"> Discount</mat-checkbox>
                <mat-form-field appearance="legacy" *ngIf="productvariantForm.value.has_discount == true">
                    <mat-label>Retail Discount Price{{perunitslash}}</mat-label>
                    <input matInput type="text" placeholder="" formControlName="discount_price" />
                    <mat-error *ngIf="productvariantForm.get('discount_price').hasError('required')">
                        Retail discount price is required
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="legacy">
                    <mat-label>Whole Sale Price{{perunitslash}}</mat-label>
                    <input matInput type="text" placeholder="" formControlName="wholesale_price" />
                    <mat-error *ngIf="productvariantForm.get('wholesale_price').hasError('required')">
                        Wholesale price is required
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="legacy">
                    <mat-label>Minimum Order Quantity in {{perunit}}</mat-label>
                    <input matInput type="text" placeholder="" formControlName="min_qty" />
                    <mat-error *ngIf="productvariantForm.get('min_qty').hasError('required')">
                        Minimum order quantity is required
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="legacy">
                    <mat-label>Step Order Quantity in {{perunit}}</mat-label>
                    <input matInput type="text" placeholder="" formControlName="step_qty" />
                    <mat-error *ngIf="productvariantForm.get('step_qty').hasError('required')">
                        Step order quantity is required
                    </mat-error>
                </mat-form-field>
                <mat-checkbox formControlName="isactive"> Active</mat-checkbox>
            </div>
            <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
                <ejs-richtexteditor #toolsRTE id="alltoolRTE" [toolbarSettings]="tools"
                    formControlName="variant_description"></ejs-richtexteditor>
                <mat-form-field appearance="legacy">
                    <ngx-mat-file-input (change)="fileProgress($event, 'img_paths')" placeholder="Variant images" formControlName="gallery" [accept]="'.png, .jpg'"
                        multiple></ngx-mat-file-input>
                    <mat-error *ngIf="productvariantForm.get('gallery').hasError('maxContentSize')">
                        Maximum file size is 1MB
                    </mat-error>
                </mat-form-field>
                <div *ngIf="img_paths.length > 0">
                    <div class="image" *ngFor="let item of img_paths;let indexOfelement=index;">
                        <button mat-button color="primary" aria-label="delete" (click)="removeImage(indexOfelement)">
                            Delete
                        </button>
                        <img [src]="item" alt="image" />
                    </div>
                </div>
                <button [disabled]="!productvariantForm.valid || loading" mat-raised-button color="primary" type="submit">
                    <span *ngIf="loading == false">Add Product Variant</span>
                    <mat-progress-spinner *ngIf="loading == true" color="accent" mode="indeterminate" strokeWidth="4"
                        diameter="40" class="auto-margin"></mat-progress-spinner>
                </button>
            </div>
        </form>
    </div>
</div>