import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSelect } from '@angular/material/select';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { Ecommerce_Config } from '../Ecommerce_Config';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { InventoryDetail, UserQuickAddDialog, ConfirmDialog } from '../dialog/dialog.component';
import { DatePipe } from '@angular/common';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
const pdfMake = require('pdfmake/build/pdfmake.js');
import * as pdfFonts from "pdfmake/build/vfs_fonts";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-sale',
  templateUrl: './sale.component.html',
  styleUrls: ['./sale.component.css']
})
export class SaleComponent implements OnInit {
  sort_option: string = 'created_at-desc';
  search_qry: string = '';
  page_length: number = 110;
  pageind: number = 0;
  loading: boolean = false;
  items: any = [];
  constructor(public route: ActivatedRoute, private _snackBar: MatSnackBar, private httpClient: HttpClient, public dialog: MatDialog) {
    if (this.route.snapshot.params.category) {
    }
    Ecommerce_Config["page_title"] = 'Sale';
  }
  getSaleData(ev) {
    this.getSale(ev.pageIndex);
  }
  getSale(page) {
    this.pageind = page;
    page = page + 1;
    this.loading = true;
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    var query = null;
    if (this.search_qry != '') {
      query = this.search_qry;
    }
    this.httpClient.get(Ecommerce_Config["api_url"] + 'sale/' + query + '/' + this.sort_option + '/' + page, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.items = res["data"];
          this.page_length = res["count"];
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }
  ngOnInit(): void {
    this.getSale(0);
  }
  showProducts(row) {
    const dialogRef = this.dialog.open(InventoryDetail, {
      minWidth: "40%",
      maxWidth: "40%",
      disableClose: true,
      data: row
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

}

@Component({
  selector: 'app-add-sale',
  templateUrl: './add.sale.component.html',
  styleUrls: ['./sale.component.css']
})

export class AddSaleComponent implements OnInit {
  @ViewChild('product_dropdown', { static: false }) product_dropdown: MatSelect;
  @ViewChild('quantity', { static: false }) quantity: ElementRef;
  @ViewChild('paid_amt_ele', { static: false }) paid_amt_ele: ElementRef;
  @ViewChild('gst_percent_ele', { static: false }) gst_percent_ele: ElementRef;
  saleProductForm: FormGroup;
  productvariantCtrl: FormControl = new FormControl();
  userCtrl: FormControl = new FormControl();
  productvariants: any = [];
  users: any = [];
  allproductvariants: any = [];
  displayedColumns: string[] = ['product', 'unit', 'price'];
  inventoryproducts: any = { paidamt: 0, remark: '', items: [], inventory_type: 'sale', user: 0, needprint: true };
  loading_options: boolean = false;
  loading: boolean = false;
  unitin: string = '';
  unitslash: string = '';
  paid_amt: number = null;
  gst_percent: number = 18;
  enable_gst: boolean = false;
  receiptPDF: any = '';
  storename: string = sessionStorage.getItem("store_name");
  phone: string = sessionStorage.getItem("phone");
  email: string = sessionStorage.getItem("email");
  address1: string = sessionStorage.getItem("address1");
  address2: string = sessionStorage.getItem("address2");
  gst: string = sessionStorage.getItem("gst");
  logo: string = sessionStorage.getItem("logo");
  is_inventory_based: string = sessionStorage.getItem("is_inventory_based");
  image_url: string = Ecommerce_Config['img_url'];
  constructor(public route: ActivatedRoute, private _hotkeysService: HotkeysService, public sanitizer: DomSanitizer, private datePipe: DatePipe, private _snackBar: MatSnackBar, private httpClient: HttpClient, private router: Router, public dialog: MatDialog, private cdRef: ChangeDetectorRef) {
    this.saleProductForm = new FormGroup({
      'product_name': new FormControl('', [Validators.required]),
      'product': new FormControl('', [Validators.required]),
      'product_qty': new FormControl(null, [Validators.required]),
      'product_price': new FormControl(null),
      'product_price_type': new FormControl('retail'),
      'product_unit': new FormControl(null, Validators.required),
      'unit_name': new FormControl(null, Validators.required),
      'user': new FormControl(0)
    });
    /*this.getBase64Image(this.image_url + this.logo, (base64) => {
      console.log(base64);
    });*/
  }
  addProductToInventory() {
    var stock = 0;
    this.productvariants.forEach(v => {
      if (v.mv_product_variant_id == this.saleProductForm.value.product) {
        stock = v.stock_in_hand;
        this.saleProductForm.patchValue({
          product_price: v.price,
          product_price_type: 'retail'
        });
        return false;
      }
    });
    if ((this.is_inventory_based == '1' && stock >= this.saleProductForm.value.product_qty) || this.is_inventory_based == '0') {
      (this.inventoryproducts.items).push({ product: this.saleProductForm.value.product, product_name: this.saleProductForm.value.product_name, unit_name: this.saleProductForm.value.unit_name, product_qty: this.saleProductForm.value.product_qty, product_price: this.saleProductForm.value.product_price, product_price_type: this.saleProductForm.value.product_price_type, product_unit: this.saleProductForm.value.product_unit, product_total: (this.saleProductForm.value.product_price * this.saleProductForm.value.product_qty) });
      this.saleProductForm.reset();
      this.productvariantCtrl.setValue('');
      this.product_dropdown.focus();
    } else {
      this._snackBar.open('Stock left only ' + stock + ' ' + this.saleProductForm.value.unit_name, '', {
        duration: 2000,
      });
    }
  }
  addProductToInventoryw() {
    var stock = 0;
    this.productvariants.forEach(v => {
      if (v.mv_product_variant_id == this.saleProductForm.value.product) {
        stock = v.stock_in_hand;
        this.saleProductForm.patchValue({
          product_price: v.price_wholesale,
          product_price_type: 'wholesale'
        });
        return false;
      }
    });
    if ((this.is_inventory_based == '1' && stock >= this.saleProductForm.value.product_qty) || this.is_inventory_based == '0') {
      (this.inventoryproducts.items).push({ product: this.saleProductForm.value.product, product_name: this.saleProductForm.value.product_name, unit_name: this.saleProductForm.value.unit_name, product_qty: this.saleProductForm.value.product_qty, product_price: this.saleProductForm.value.product_price, product_price_type: this.saleProductForm.value.product_price_type, product_unit: this.saleProductForm.value.product_unit, product_total: (this.saleProductForm.value.product_price * this.saleProductForm.value.product_qty) });
      this.saleProductForm.reset();
      this.productvariantCtrl.setValue('');
      this.product_dropdown.focus();
    } else {
      this._snackBar.open('Stock left only ' + stock + ' ' + this.saleProductForm.value.unit_name, '', {
        duration: 2000,
      });
    }
  }
  getGrandTotal() {
    var total = 0;
    this.inventoryproducts.items.forEach(v => {
      total = total + v.product_total;
    });
    return total;
  }
  getGSTTotal() {
    var res = 0;
    if (this.enable_gst == true) {
      var total = this.getGrandTotal();
      res = total * (this.gst_percent / 100);
    }
    return res;
  }
  checkPaidAmt() {
    if (this.inventoryproducts.paidamt == '' || this.inventoryproducts.paidamt == null) {
      const dialogRef = this.dialog.open(ConfirmDialog, {
        minWidth: "30%",
        maxWidth: "30%",
        disableClose: true,
        data: { title: 'Confirmation', message: 'Are you sure to continue without paid amount?', cancel: 'No', accept: 'Yes' }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result == true) {
          this.inventoryproducts.paidamt = 0;
          this.addInventory();
        }
      });
    } else {
      this.addInventory();
    }
  }
  doSubmissionValidation() {
    if (this.paid_amt == null) {
      this._snackBar.open('Paid amount should not be empty', '', {
        duration: 2000,
      });
      return false;
    }
    if (this.inventoryproducts.user == 0) {
      this._snackBar.open('Please select user', '', {
        duration: 2000,
      });
      return false;
    }
    return true;
  }
  addInventory() {
    if (this.doSubmissionValidation()) {
      const dialogRef = this.dialog.open(ConfirmDialog, {
        minWidth: "30%",
        maxWidth: "30%",
        disableClose: true,
        data: { title: 'Confirmation', message: 'Do you need print?', cancel: 'No', accept: 'Yes' }
      });
      dialogRef.afterClosed().subscribe(result => {
        this.loading = true;
        var token = sessionStorage.getItem("auth_token");
        var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
        this.inventoryproducts.gst_percent = (this.enable_gst == true ? this.gst_percent : 0);
        this.inventoryproducts.gst_amount = this.getGSTTotal();
        this.inventoryproducts.grand_total = (this.getGrandTotal() + this.getGSTTotal());
        this.inventoryproducts.paidamt = this.paid_amt;
        this.httpClient.post(Ecommerce_Config["api_url"] + 'sale', this.inventoryproducts, { headers: header }).subscribe(
          (res) => {
            this.loading = false;
            if (res["code"] == 200) {
              if (result && result == true) {
                this.generatePDF(res["order_id"], this.inventoryproducts);
              } else {
                this.inventoryproducts.items = [];
              }
              this.product_dropdown.focus();
              this._snackBar.open('Sale added successfully', '', {
                duration: 2000,
              });
            } else {
              this._snackBar.open(res["message"], '', {
                duration: 2000,
              });
            }
          },
          (error) => {
            this.loading = false;
            this._snackBar.open(error.error.message, '', {
              duration: 2000,
            });
          });
      });
    }
  }
  generatePDF(billno, bill) {
    var record = bill;
    var product_lines = [];
    var line = 1;
    var company_name = '';
    var company_address = '';
    var company_gst = '';
    var company_phone = '';
    var found = false;
    (record.items).forEach(element => {
      var margin = [0, 0, 0, 5];
      if (line == record.items.length) {
        margin = [0, 0, 0, 5];
      }
      (product_lines).push({
        columns: [{ text: line, width: 40, alignment: 'left', fontSize: 10, margin: margin },
        { text: element.product_name, alignment: 'left', fontSize: 10, margin: margin },
        { text: element.product_qty + element.unit_name + 'X ₹' + parseFloat(element.product_price).toFixed(2), alignment: 'left', fontSize: 10, margin: margin },
        { text: '₹' + parseFloat(element.product_total).toFixed(2), alignment: 'right', width: 60, fontSize: 10, margin: margin }]
      });
      line++;
    });
    (this.users).forEach(element => {
      if (!found && element.mv_user_id == record.user) {
        found = true;
        company_name = element.name;
        company_address = element.address;
        company_gst = element.gst;
        company_phone = element.mobile;
      }
    });
    this.getBase64Image(this.image_url + this.logo, (base64) => {
      let docDefinition = {
        pageSize: {
          width: 288,
          height: 'auto'
        },
        pageMargins: [10, 20, 10, 20],
        content: [
          {
            columns: [
              {
                text: 'Bill No.: #' + billno,
                fontSize: 16,
                alignment: 'left',
                bold: true
              },
              {
                width: 130,
                alignment: 'right',
                image: base64
              },
            ]
          },
          { text: 'Date: ' + this.datePipe.transform(new Date(), 'dd-MMM-yyyy hh:mm a'), bold: true, fontSize: 10, alignment: 'left', margin: [0, 0, 0, 5] },
          {
            canvas:
              [
                {
                  type: 'line',
                  x1: 0, y1: 0,
                  x2: 268, y2: 0,
                  lineWidth: 1,
                },
              ]
          },
          {
            columns: [
              { text: 'Store Details:', bold: true, fontSize: 10, alignment: 'left', margin: [0, 5, 0, 0] },
              { text: 'Customer Details:', bold: true, fontSize: 10, alignment: 'right', margin: [0, 5, 0, 0] },
            ]
          },
          {
            columns: [
              { text: this.storename, alignment: 'left', fontSize: 10 },
              { text: company_name, alignment: 'right', fontSize: 10 },
            ]
          },
          {
            columns: [
              { text: (this.address1 + ', ' + this.address2), alignment: 'left', fontSize: 10 },
              { text: company_address, alignment: 'right', fontSize: 10 },
            ]
          },
          {
            columns: [
              { text: 'Ph: ' + this.phone, alignment: 'left', fontSize: 10 },
              { text: 'Ph: ' + company_phone, alignment: 'right', fontSize: 10 },
            ]
          },
          {
            columns: [
              { text: 'GST: ' + this.gst, alignment: 'left', margin: [0, 0, 0, 5], fontSize: 10 },
              { text: 'GST: ' + company_gst, alignment: 'right', margin: [0, 0, 0, 5], fontSize: 10 },
            ]
          },
          {
            canvas:
              [
                {
                  type: 'line',
                  x1: 0, y1: 0,
                  x2: 268, y2: 0,
                  lineWidth: 1,
                },
              ]
          },
          {
            columns: [
              { text: 'Sl. No.', width: 40, alignment: 'left', margin: [0, 5, 0, 5], fontSize: 10, bold: true },
              { text: 'Product', alignment: 'left', margin: [0, 5, 0, 5], fontSize: 10, bold: true },
              { text: 'Quantity', alignment: 'left', margin: [0, 5, 0, 5], fontSize: 10, bold: true },
              { text: 'Total', alignment: 'right', width: 60, margin: [0, 5, 0, 5], fontSize: 10, bold: true },
            ]
          },
          product_lines,
          {
            canvas:
              [
                {
                  type: 'line',
                  x1: 0, y1: 0,
                  x2: 268, y2: 0,
                  lineWidth: 1,
                },
              ]
          },
          {
            columns: [
              { text: 'GST' + (record.gst_percent > 0 ? (record.gst_percent + '%') : ''), alignment: 'right', margin: [0, 5, 0, 0], fontSize: 14, bold: true },
              { text: '₹' + (record.gst_amount > 0 ? parseFloat(record.gst_amount).toFixed(2) : '0.00'), alignment: 'right', width: 100, margin: [0, 5, 0, 0], fontSize: 14, bold: true },
            ]
          },
          {
            columns: [
              { text: 'Grand Toatal', alignment: 'right', margin: [0, 5, 0, 0], fontSize: 14, bold: true },
              { text: '₹' + parseFloat(record.grand_total).toFixed(2), alignment: 'right', width: 100, margin: [0, 5, 0, 0], fontSize: 14, bold: true },
            ]
          },
          { text: '\n' },
          { text: '\n' },
          { text: 'Thank you! Visit again!!', alignment: 'center', fontSize: 9 },
          { text: 'Powered by https://www.mdmacrm.com', alignment: 'center', fontSize: 9 }
        ],
        styles: {
          tableExample: {
            margin: [5, 10, 5, 10],
          }
        }
      };
      this.inventoryproducts.items = [];
      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      pdfDocGenerator.getDataUrl((dataUrl: any) => {
        this.receiptPDF = this.sanitizer.bypassSecurityTrustResourceUrl(dataUrl);
      });
    });
  }
  async getBase64Image(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }
  ngOnInit(): void {
    this._hotkeysService.add(new Hotkey('f9', (event: KeyboardEvent): boolean => {
      if (!this.saleProductForm.invalid) {
        this.addProductToInventory();
      }
      return false; // Prevent bubbling
    }, ['INPUT', 'TEXTAREA', 'SELECT']));
    this._hotkeysService.add(new Hotkey('f11', (event: KeyboardEvent): boolean => {
      if (!this.saleProductForm.invalid) {
        this.addProductToInventoryw();
      }
      return false; // Prevent bubbling
    }, ['INPUT', 'TEXTAREA', 'SELECT']));
    this._hotkeysService.add(new Hotkey('f2', (event: KeyboardEvent): boolean => {
      if (this.inventoryproducts.items.length > 0) {
        this.addInventory();
      }
      return false; // Prevent bubbling
    }, ['INPUT', 'TEXTAREA', 'SELECT']));
    this._hotkeysService.add(new Hotkey('f1', (event: KeyboardEvent): boolean => {
      this.paid_amt_ele.nativeElement.focus();
      return false; // Prevent bubbling
    }, ['INPUT', 'TEXTAREA', 'SELECT']));
    this._hotkeysService.add(new Hotkey('f3', (event: KeyboardEvent): boolean => {
      this.gst_percent_ele.nativeElement.focus();
      return false; // Prevent bubbling
    }, ['INPUT', 'TEXTAREA', 'SELECT']));
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.userCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.users = [];
          this.loading_options = true;
        }),
        switchMap(srch => this.httpClient.get<any>(Ecommerce_Config["api_url"] + 'vendorncustomer_autocomplete/' + srch, { headers: header })
          .pipe(
            finalize(() => {
              this.loading_options = false
            }),
          )
        )
      )
      .subscribe(res => {
        if (res["code"] == 200) {
          this.users = res["data"];
        } else {
          this.users = [];
        }
      });
    this.httpClient.get(Ecommerce_Config["api_url"] + 'allproductvariant', { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.allproductvariants = res["data"];
          this.productvariants = res["data"];
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }
  ngAfterViewInit(): void {
    this.product_dropdown.focus();
    this.cdRef.detectChanges();
  }
  selectedOption(val, param, array, idparam, matchval) {
    this[array].forEach(v => {
      if (v[matchval] == val) {
        this.inventoryproducts[param] = v[idparam];
        return false;
      }
    });
  }
  selectedOptionProduct(val, array) {
    this[array].forEach(v => {
      if (v.mv_product_variant_id == val) {
        this.unitin = 'in ' + v.unit;
        this.unitslash = '/' + v.unit;
        this.saleProductForm.patchValue({
          product_unit: v.mv_unit_id,
          unit_name: v.unit,
          product_name: v.name
        });
        setTimeout(() => {
          this.quantity.nativeElement.focus();
        }, 100);
        return false;
      }
    });
  }
  removeProduct(ind) {
    this.inventoryproducts.items.splice(ind, 1);
    this.product_dropdown.focus();
  }
  addUser() {
    var row = { type: 'vendor', label: 'Customer' }
    const dialogRef = this.dialog.open(UserQuickAddDialog, {
      minWidth: "40%",
      maxWidth: "40%",
      disableClose: true,
      data: row
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }

}