import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Ecommerce_Config } from '../Ecommerce_Config';
import { ImagePreviewDialog, ConfirmDialog } from '../dialog/dialog.component';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, TableService } from '@syncfusion/ej2-angular-richtexteditor';
import { FileValidator } from 'ngx-material-file-input';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  more_option: string = '';
  sort_option: string = 'created_at-desc';
  search_qry: string = '';
  page_length: number = 110;
  pageind: number = 0;
  loading: boolean = false;
  more_action_ids: string[] = [];
  items: any = [];
  selectedTabIndex: number = 0;
  role: String[] = ['supplier', 'vendor'];
  img_url: string = Ecommerce_Config.img_url;
  constructor(public route: ActivatedRoute, private _snackBar: MatSnackBar, private httpClient: HttpClient, public dialog: MatDialog) {
    Ecommerce_Config["page_title"] = 'User';
  }
  onTabChanged(ev) {
    this.selectedTabIndex = ev.index;
    this.getUser(0);
  }
  getUserData(ev) {
    this.getUser(ev.pageIndex);
  }
  getUser(page) {
    this.more_option = '';
    this.more_action_ids = [];
    this.pageind = page;
    page = page + 1;
    this.loading = true;
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    var query = null;
    if (this.search_qry != '') {
      query = this.search_qry;
    }
    this.httpClient.get(Ecommerce_Config["api_url"] + 'user/' + query + '/' + this.role[this.selectedTabIndex] + '/' + this.sort_option + '/' + page, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.items = res["data"];
          this.page_length = res["count"];
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }
  toggleMoreOption(ev, code) {
    var index = (this.more_action_ids).indexOf(code);
    if (ev.checked == true && index < 0) {
      (this.more_action_ids).push(code);
    } else {
      (this.more_action_ids).splice(index, 1);
    }
    if (this.more_action_ids.length == 0) {
      this.more_option = '';
    }
  }
  checkedItem(code) {
    return (this.more_action_ids).indexOf(code) > -1;
  }
  doMoreOption() {
    var msg = '';
    switch (this.more_option) {
      case 'active':
        msg = 'Are you sure? Do you want to active these ' + this.more_action_ids.length + ' users?';
        break;
      case 'deactive':
        msg = 'Are you sure? Do you want to deactive these ' + this.more_action_ids.length + ' users?';
        break;
      default:
        break;
    }
    const dialogRef = this.dialog.open(ConfirmDialog, {
      minWidth: "30%",
      maxWidth: "30%",
      disableClose: true,
      data: { title: 'Confirmation', message: msg, cancel: 'No', accept: 'Yes' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result == true) {
        this.loading = true;
        var token = sessionStorage.getItem("auth_token");
        var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
        this.httpClient.put(Ecommerce_Config["api_url"] + 'usermoreoption/' + this.more_option, { codes: this.more_action_ids }, { headers: header }).subscribe(
          (res) => {
            this.loading = false;
            this._snackBar.open(res["message"], '', {
              duration: 2000,
            });
            if (res["code"] == 200) {
              this.more_action_ids = [];
              this.more_option = '';
              this.getUser(this.pageind);
            }
          },
          (error) => {
            this.loading = false;
            this._snackBar.open(error.error.message, '', {
              duration: 2000,
            });
          });
      }
    });
  }
  changeStatus(ev, code) {
    var msg = '';
    if (ev.checked == true) {
      msg = 'Are you sure? Do you want to enable this user?';
    } else {
      msg = 'Are you sure? Do you want to disable this user?';
    }
    const dialogRef = this.dialog.open(ConfirmDialog, {
      minWidth: "30%",
      maxWidth: "30%",
      disableClose: true,
      data: { title: 'Confirmation', message: msg, cancel: 'No', accept: 'Yes' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result == true) {
        this.loading = true;
        var token = sessionStorage.getItem("auth_token");
        var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
        this.httpClient.put(Ecommerce_Config["api_url"] + 'toggleuserstate/' + code, { isactive: ev.checked }, { headers: header }).subscribe(
          (res) => {
            this.loading = false;
            this._snackBar.open(res["message"], '', {
              duration: 2000,
            });
            if (res["code"] != 200) {
              (this.items).forEach(val => {
                if (val.code == code) {
                  val.status = !val.status;
                  return false;
                }
              });
            }
          },
          (error) => {
            this.loading = false;
            this._snackBar.open(error.error.message, '', {
              duration: 2000,
            });
          });
      } else {
        (this.items).forEach(val => {
          if (val.code == code) {
            val.status = !val.status;
            return false;
          }
        });
      }
    });
  }
  previewImage(ttl, path) {
    const dialogRef = this.dialog.open(ImagePreviewDialog, {
      minWidth: "30%",
      maxWidth: "30%",
      disableClose: true,
      data: { title: ttl, path: path, accept: 'Close' }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }
  showOrders(row) {

  }
  showAddress(row) {

  }
  ngOnInit(): void {
    this.getUser(0);
  }
}

@Component({
  selector: 'app-add-user',
  templateUrl: './add.user.component.html',
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, TableService],
  styleUrls: ['./user.component.css']
})
export class AddUserComponent implements OnInit {
  loading = false;
  userForm: FormGroup;
  constructor(private _snackBar: MatSnackBar, private router: Router, private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.userForm = new FormGroup({
      'name': new FormControl('', [Validators.required]),
      'company_name': new FormControl('', [Validators.required]),
      'gst': new FormControl('', [Validators.required]),
      'address': new FormControl('', [Validators.required]),
      'email': new FormControl('', [Validators.required]),
      'mobile': new FormControl('', [Validators.required]),
      'role': new FormControl('vendor', [Validators.required]),
      'isactive': new FormControl(true, [Validators.required])
    });
  }
  addUser() {
    if (this.userForm.invalid) {
      return;
    }
    this.loading = true;
    var formData = { name: this.userForm.value.name, company_name: this.userForm.value.company_name, gst: this.userForm.value.gst, address: this.userForm.value.address, email: this.userForm.value.email, mobile: this.userForm.value.mobile, role: this.userForm.value.role, isactive: this.userForm.value.isactive };
    if (this.userForm.value.isactive == false) {
      formData.isactive = '0';
    }
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.httpClient.post(Ecommerce_Config["api_url"] + 'user', formData, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.router.navigateByUrl('/user');
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }
}

@Component({
  selector: 'app-update-user',
  templateUrl: './update.user.component.html',
  styleUrls: ['./user.component.css']
})
export class UpdateUserComponent implements OnInit {
  user_code: string = '';
  loading = false;
  userForm: FormGroup;
  constructor(public route: ActivatedRoute, private _snackBar: MatSnackBar, private httpClient: HttpClient, private router: Router) {
    this.user_code = this.route.snapshot.params.code;
    this.userForm = new FormGroup({
      'name': new FormControl('', [Validators.required]),
      'company_name': new FormControl('', [Validators.required]),
      'gst': new FormControl('', [Validators.required]),
      'address': new FormControl('', [Validators.required]),
      'email': new FormControl('', [Validators.required]),
      'mobile': new FormControl('', [Validators.required]),
      'role': new FormControl('vendor', [Validators.required]),
      'isactive': new FormControl(true, [Validators.required])
    });
  }
  getUser() {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.httpClient.get(Ecommerce_Config["api_url"] + 'user/' + this.user_code, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.userForm.patchValue({
            name: res["data"]["name"],
            company_name: res["data"]["company_name"],
            gst: res["data"]["gst"],
            address: res["data"]["address"],
            email: res["data"]["email"],
            mobile: res["data"]["mobile"],
            role: res["data"]["role"],
            isactive: res["data"]["status"]
          });
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }
  updateUser() {
    if (this.userForm.invalid) {
      return;
    }
    this.loading = true;
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.httpClient.put(Ecommerce_Config["api_url"] + 'user/' + this.user_code, { name: this.userForm.value.name, company_name: this.userForm.value.company_name, gst: this.userForm.value.gst, address: this.userForm.value.address, email: this.userForm.value.email, mobile: this.userForm.value.mobile, role: this.userForm.value.role, isactive: this.userForm.value.isactive }, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.router.navigateByUrl('/user');
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }
  ngOnInit(): void {
    this.getUser();
  }
}