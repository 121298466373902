import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { Ecommerce_Config } from '../Ecommerce_Config';
import { AlertDialog } from '../dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('email', { static: false }) email: ElementRef;
  loading = false;
  loginForm: FormGroup;
  constructor(private _snackBar: MatSnackBar, private router: Router, public dialog: MatDialog, private httpClient: HttpClient, private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      'user_name': new FormControl('', [Validators.required]),
      'password': new FormControl('', [Validators.required])
    });
  }
  ngAfterViewInit(): void {
    this.email.nativeElement.focus();
    this.cdRef.detectChanges();
  }

  loginAdmin() {
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    this.httpClient.post(Ecommerce_Config["api_url"] + 'loginadmin', { email: this.loginForm.value.user_name, password: this.loginForm.value.password, device_token: window.localStorage.getItem("web_push_token") }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          sessionStorage.setItem("auth_token", res["data"]["token"]);
          sessionStorage.setItem("name", res["data"]["name"]);
          sessionStorage.setItem("store_name", res["data"]["store_name"]);
          sessionStorage.setItem("phone", res["data"]["phone"]);
          sessionStorage.setItem("email", res["data"]["email"]);
          sessionStorage.setItem("address1", res["data"]["address1"]);
          sessionStorage.setItem("address2", res["data"]["address2"]);
          sessionStorage.setItem("gst", res["data"]["gst"]);
          sessionStorage.setItem("logo", res["data"]["logo"]);
          sessionStorage.setItem("is_inventory_based", res["data"]["is_inventory_based"]);
          this.router.navigateByUrl('/dashboard');
        } else {
          const dialogRef = this.dialog.open(AlertDialog, {
            minWidth: "30%",
            maxWidth: "30%",
            disableClose: true,
            data: { title: 'Information', message: res["message"], accept: 'Yes' }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result && result == true) {

            }
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      }
    );
  }

}
