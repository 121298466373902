import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialog } from '../dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Ecommerce_Config } from '../Ecommerce_Config';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {
  title: string = Ecommerce_Config["page_title"];
  loading: boolean = false;
  username: string = sessionStorage.getItem("name");
  storename: string = sessionStorage.getItem("store_name");
  phone: string = sessionStorage.getItem("phone");
  email: string = sessionStorage.getItem("email");
  address1: string = sessionStorage.getItem("address1");
  address2: string = sessionStorage.getItem("address2");
  gst: string = sessionStorage.getItem("gst");
  logo: string = sessionStorage.getItem("logo");
  image_url: string = Ecommerce_Config['img_url'];
  constructor(private httpClient: HttpClient, private _snackBar: MatSnackBar, public route: ActivatedRoute, public router: Router, public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  logout() {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      minWidth: "30%",
      maxWidth: "30%",
      disableClose: true,
      data: { title: 'Confirmation', message: 'Are you sure? Do you want to logout?', cancel: 'No', accept: 'Yes' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result == true) {
        sessionStorage.removeItem("auth_token");
        this.router.navigateByUrl('/login');
      }
    });
  }
}
