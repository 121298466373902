import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Ecommerce_Config } from '../Ecommerce_Config';
import { InventoryDetail } from '../dialog/dialog.component';
import * as moment from 'moment';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  sort_option: string = 'p.created_at-desc';
  search_qry: string = '';
  loading: boolean = false;
  date: Date = new Date();
  items: any = [];
  constructor(public route: ActivatedRoute, private _snackBar: MatSnackBar, private httpClient: HttpClient, public dialog: MatDialog) {
    if (this.route.snapshot.params.category) {
    }
    Ecommerce_Config["page_title"] = 'Payment';
  }
  getPayment() {
    this.loading = true;
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    var query = null;
    if (this.search_qry != '') {
      query = this.search_qry;
    }
    this.httpClient.get(Ecommerce_Config["api_url"] + 'payment/' + query + '/' + this.sort_option + '/' + moment(this.date).format('YYYY-MM-DD'), { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.items = res["data"];
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }
  ngOnInit(): void {
    this.getPayment();
  }
  showProducts(row) {
    const dialogRef = this.dialog.open(InventoryDetail, {
      minWidth: "40%",
      maxWidth: "40%",
      disableClose: true,
      data: row
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }
}

@Component({
  selector: 'app-add-payment',
  templateUrl: './add.payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class AddPaymentComponent implements OnInit {
  @ViewChild('userdropdown', { static: false }) userdropdown: ElementRef;
  loading = false;
  paid_amt: number = null;
  userCtrl: FormControl = new FormControl();
  loading_options: boolean = false;
  users: any = [];
  payment: any = { user: 0 };
  displayedColumns: string[] = ['selected', 'mv_inventory_id', 'grand_total', 'balance_to_be_pay'];
  dataSource: any = [];
  selected_inv = [];
  constructor(private _snackBar: MatSnackBar, private router: Router, private httpClient: HttpClient) { }
  ngOnInit(): void {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.userCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.users = [];
          this.loading_options = true;
        }),
        switchMap(srch => this.httpClient.get<any>(Ecommerce_Config["api_url"] + 'vendorncustomer_autocomplete/' + srch, { headers: header })
          .pipe(
            finalize(() => {
              this.loading_options = false
            }),
          )
        )
      )
      .subscribe(res => {
        if (res["code"] == 200) {
          this.users = res["data"];
        } else {
          this.users = [];
        }
      });
    setTimeout(() => {
      this.userdropdown.nativeElement.focus();
    }, 100);
  }
  selectedOption(val, param, array, idparam, matchval) {
    this[array].forEach(v => {
      if (v[matchval] == val) {
        this.payment[param] = v[idparam];
        return false;
      }
    });
    if (param == 'user') {
      this.loadOpenInvoices();
    }
  }
  loadOpenInvoices() {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.httpClient.get(Ecommerce_Config["api_url"] + 'get_open_bills_by_user/' + this.payment.user, { headers: header }).subscribe(
      (res) => {
        if (res["code"] == 200) {
          res['data'].forEach(element => {
            element.selected = false;
          });
          this.dataSource = res['data'];
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }
  addInvoiceToPay(ev, bid, bal) {
    var ind = this.selected_inv.indexOf(bid);
    if (ind > -1) {
      this.paid_amt = this.paid_amt - bal;
      this.selected_inv.splice(ind, 1);
    } else {
      this.paid_amt = this.paid_amt + bal;
      this.selected_inv.push(bid);
    }
  }
  validPayment() {
    if ((this.selected_inv).length == 0) {
      this._snackBar.open('Please select atleast one invoice', '', {
        duration: 2000,
      });
      return false;
    }
    if (this.paid_amt == null || this.paid_amt <= 0) {
      this._snackBar.open('Amount should not be 0 or empty', '', {
        duration: 2000,
      });
      return false;
    }
    return true;
  }
  addPayment() {
    if (this.validPayment()) {
      this.loading = true;
      var token = sessionStorage.getItem("auth_token");
      var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
      this.httpClient.post(Ecommerce_Config["api_url"] + 'make_payment', { bills: this.selected_inv, amount: this.paid_amt }, { headers: header }).subscribe(
        (res) => {
          this.loading = false;
          if (res["code"] == 200) {
            this.selected_inv = [];
            this.paid_amt = 0;
            this.loadOpenInvoices();
          } else {
            this._snackBar.open(res["message"], '', {
              duration: 2000,
            });
          }
        },
        (error) => {
          this.loading = false;
          this._snackBar.open(error.error.message, '', {
            duration: 2000,
          });
        });
    }
  }
}